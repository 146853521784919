import React, { useEffect, useState } from "react";
import TotalSurveyIcon from "../../assets/icons/TotalSurveyIcon.svg";
import TotalLiveSurveyIcon from "../../assets/icons/TotalLiveSurvey.svg";
import TotalCompleatSurvey from "../../assets/icons/TotalCompleateSurvey.svg";
import { useNavigate } from "react-router-dom";

import { useGlobalContext } from "../../Contexts/GlobalContext";
const Invoice = () => {
  const { allInvoices } = useGlobalContext();
  const navigate = useNavigate();
  const [filters, setFilters] = useState({});

  //GET LAST 10 YEARS:
  const [monthYear, setMonthYear] = useState({});
  async function getYears() {
    let tempArr = [];
    for (let i = 0; i <= 10; i++) {
      tempArr.push(new Date()?.getFullYear() - i);
    }
    let months = [
      { month: "January", value: 0 },
      { month: "February", value: 1 },
      { month: "March", value: 2 },
      { month: "April", value: 3 },
      { month: "May", value: 4 },
      { month: "June", value: 5 },
      { month: "July", value: 6 },
      { month: "August", value: 7 },
      { month: "September", value: 8 },
      { month: "October", value: 9 },
      { month: "November", value: 10 },
      { month: "December", value: 11 },
    ];
    setMonthYear((prev) => ({ ...prev, years: tempArr, months: months }));
  }

  useEffect(() => {
    getYears();
  }, []);

  // console.log(new Date(allInvoices?.[0]?.invoiceDate).getMonth());
  // console.log(filters);
  // console.log("allInvoices=>", allInvoices);
  return (
    <div className="w-full flex flex-col gap-[30px] py-6">
      <div className="w-full flex items-center justify-between ">
        <h1 className="text-[#333] text-[32px] font-[500] inter">Invoices</h1>

        {/* {allInvoices.length ? (
          <button
            className="bg-[#0226BE] text-[#fff] py-2 px-4 rounded-lg text-[14px] font-[600]"
            onClick={(e) => {
              // navigate("/payment");
              window.location.href =
                "https://buy.stripe.com/cN24hX3hHbe74Mg6oq?prefilled_email=sohrab.singh%40peekator.com";
            }}
          >
            Pay All Due Invoices
          </button>
        ) : (
          ""
        )} */}
      </div>
      {/* survey card */}
      <div className="w-full grid grid-cols-5 gap-10">
        <div className="surveyCardShadow rounded-[10px] bg-[#fff]  py-4 px-5 flex flex-col gap-2">
          <img src={TotalSurveyIcon} alt="" className="w-[26px] h-[27px]" />
          <h3 className="text-[#000] inter font-[600] text-[18px]">
            {allInvoices?.length || 0}
          </h3>
          <p className="text-[#000A12] text-[14px] font-[500] inter">
            Total Invoices
          </p>
          {/* <h2 className="text-[#5470DE] inter text-[12px] font-[500]">
            +3% from yesterday
          </h2> */}
        </div>
        <div className="surveyCardShadow rounded-[10px] bg-[#fff]  py-4 px-5 flex flex-col gap-2">
          <img src={TotalLiveSurveyIcon} alt="" className="w-[26px] h-[27px]" />
          <h3 className="text-[#000] inter font-[600] text-[18px]">
            {" "}
            {allInvoices?.filter(
              (d) => d?.paymentStats?.toLowerCase() === "paid"
            )?.length || 0}
          </h3>
          <p className="text-[#000A12] text-[14px] font-[500] inter">
            Total Paid Invoices
          </p>
          {/* <h2 className="text-[#20AEF3] inter text-[12px] font-[500]">
            +3% from yesterday
          </h2> */}
        </div>
        <div className="surveyCardShadow rounded-[10px] bg-[#fff]  py-4 px-5 flex flex-col gap-2">
          <img src={TotalCompleatSurvey} alt="" className="w-[26px] h-[27px]" />
          <h3 className="text-[#000] inter font-[600] text-[18px]">
            {" "}
            {allInvoices?.filter(
              (d) => d?.paymentStats?.toLowerCase() === "unpaid"
            )?.length || 0}
          </h3>
          <p className="text-[#000A12] text-[14px] font-[500] inter">
            Total Unpaid Invoices
          </p>
          {/* <h2 className="text-[#FEB95A] inter text-[12px] font-[500]">
            +3% from yesterday
          </h2> */}
        </div>
      </div>
      {/* filters row */}
      <div className="flex items-center justify-between py-3">
        <div className="flex items-center gap-5">
          <select
            className="border border-[#E0E0E0] py-2 px-4 rounded-[8px] text-[#666] text-[14px] focus:outline-none"
            value={filters?.month || ""}
            onChange={(e) => {
              setFilters((prev) => ({ ...prev, month: e.target.value }));
            }}
          >
            <option value="" selected>
              All
            </option>
            {monthYear?.months?.map((data, ind) => {
              return (
                <option value={data?.value} key={ind}>
                  {data?.month}
                </option>
              );
            })}
          </select>
          <select
            className="border border-[#E0E0E0] py-2 px-4 rounded-[8px] text-[#666] text-[14px] focus:outline-none"
            value={filters?.year || ""}
            onChange={(e) => {
              setFilters((prev) => ({ ...prev, year: e.target.value }));
            }}
          >
            <option value="">All</option>
            {monthYear?.years?.map((data, ind) => {
              return (
                <option value={data} key={ind}>
                  {data}
                </option>
              );
            })}
          </select>
          <select
            className="border border-[#E0E0E0] py-2 px-4 rounded-[8px] text-[#666] text-[14px] focus:outline-none"
            value={filters?.status || ""}
            onChange={(e) => {
              setFilters((prev) => ({ ...prev, status: e.target.value }));
            }}
          >
            <option value="">Select Status</option>
            <option value="paid">Paid</option>
            <option value="unpaid">Unpaid</option>
          </select>
          <p
            className="text-[14px] underline underline-offset-4 cursor-pointer"
            onClick={(e) => setFilters({})}
          >
            Clear filters
          </p>
        </div>
      </div>
      {/* table  */}
      <div>
        {/* <div className="w-full tableBorder flex items-center justify-between px-6 py-5 ">
          <div className="flex items-center gap-4">
            <input
              type="checkbox"
              className="w-[15px] h-[15px] cursor-pointer"
            />
            <MdOutlineModeEditOutline size={24} />
            <MdCopyAll size={24} />
            <MdDownload size={24} />
            <MdDelete size={24} />
          </div>
        </div> */}
        <div className="w-full tableBorder flex flex-col">
          <div className="w-full overflow-x-auto containerScroll">
            <table
              className="whitespace-nowrap text-left w-full"
              id="table-to-xls-DATA"
            >
              <thead className=" h-[45px] ">
                <tr className="py-3">
                  {/* <th className="text-[#464545] text-[12px] font-[600] px-3">
                    <input
                      type="checkbox"
                      className="w-[15px] h-[15px] cursor-pointer"
                    />
                  </th> */}
                  <th className="text-[#464545] text-[12px] font-[600] px-3">
                    Invoice No.
                  </th>
                  {/* <th className="text-[#666] text-[12px] font-[600] px-3">
                    Supplier Name
                  </th>
                  <th className="text-[#666] text-[12px] font-[600] px-3 ">
                    Survey Group Name
                  </th> */}
                  <th className="text-[#666] text-[12px] font-[600] px-3">
                    Total Completes
                  </th>
                  <th className="text-[#666] text-[12px] font-[600] px-3">
                    Total Avg CPI
                  </th>
                  <th className="text-[#666] text-[12px] font-[600] px-3">
                    Total Revenue
                  </th>
                  <th className="text-[#666] text-[12px] font-[600] px-3">
                    Total Payable Amount
                  </th>
                  {/* <th className="text-[#666] text-[12px] font-[600] px-3">
                    Invoice Link
                  </th> */}
                  <th className="text-[#666] text-[12px] font-[600] px-3">
                    Invoice Status
                  </th>
                  <th className="text-[#666] text-[12px] font-[600] px-3">
                    Invoice Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {allInvoices
                  ?.filter((data) =>
                    !filters?.month
                      ? data
                      : new Date(data?.invoiceDate)?.getMonth() ==
                        filters?.month
                  )
                  ?.filter((data) =>
                    !filters?.year
                      ? data
                      : new Date(data?.invoiceDate)?.getFullYear() ==
                        filters?.year
                  )
                  ?.filter((data) =>
                    !filters?.status
                      ? data
                      : data?.paymentStats?.toLowerCase() == filters?.status
                  )
                  ?.map((data, ind) => (
                    <tr
                      key={ind}
                      className=" tableBorder transition-all cursor-pointer hover:bg-[#f6f9ff]"
                      onClick={(e) => navigate(`${data?._id}`)}
                    >
                      {/* {console.log(new Date(data?.invoiceDate)?.getMonth())} */}
                      {/* <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                      <input
                        type="checkbox"
                        className="w-[15px] h-[15px] cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                    </td> */}
                      <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                        <div className="flex flex-col gap-[13px] py-1">
                          {/* <p className="text-[#0226BE] underline cursor-pointer underline-offset-4"> */}
                          {data?.invoiceNumber}
                          {/* </p> */}
                        </div>
                      </td>
                      <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                        {data?.invoiceTable?.reduce(
                          (acc, cur) => (acc += cur?.totalCompletes),
                          0
                        )}
                      </td>
                      <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                        {Number(
                          data?.invoiceTable?.reduce(
                            (acc, curr) => (acc += curr?.totalRevenue),
                            0
                          ) /
                            data?.invoiceTable?.reduce(
                              (acc, cur) => (acc += cur?.totalCompletes),
                              0
                            )
                        )?.toFixed(2)}
                      </td>
                      <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                        $
                        {data?.invoiceTable?.reduce(
                          (acc, cur) => (acc += cur?.totalRevenue),
                          0
                        )}
                      </td>

                      <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                        USD{" "}
                        {data?.invoiceTable?.reduce(
                          (acc, cur) => (acc += cur?.totalRevenue),
                          0
                        )}
                      </td>

                      <td className="px-3 py-2 text-[#667085] font-[400] text-[14px] ">
                        {data?.paymentStats === "paid" ? (
                          <p className="bg-[#EDFBF4] rounded-[11px] w-[71px] h-[30px] flex items-center justify-center text-[#2FBB85] inter text-[12px] capitalize">
                            Paid
                          </p>
                        ) : (
                          <p className="bg-[#bbad2f37] rounded-[11px] w-[71px] h-[30px] flex items-center justify-center text-[#bbad2f] inter text-[12px] capitalize">
                            Unpaid
                          </p>
                        )}
                      </td>
                      <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                        {new Date(data?.invoiceDate)?.toLocaleDateString(
                          "en-ca",
                          { day: "2-digit", month: "short", year: "numeric" }
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
