import React, { useCallback, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import Hashids from "hashids";
import axios from "axios";
import LoaderBTN from "../components/LoaderBTN";
import { BASE_URL, getCurrentSurvey } from "../Utlils";
import { useGlobalContext } from "../Contexts/GlobalContext";
import Skeleton from "../components/Skeleton";
const hashids = new Hashids("My Project");
var id = hashids.encodeHex("507f1f77bcf86cd799439011");
var hex = hashids.decodeHex(id);
const SurveyQualification = () => {
  const { questionLibrary } = useGlobalContext();
  const [qualificationConfig, setQualificationConfig] = useState({
    loading: true,
    error: false,
  });
  const [survey, setSurvey] = useState({});
  const { sId, supId, orgId } = useParams();
  const surveyID = sId;
  const getSurvey = async () => {
    let data = await axios.get(`${BASE_URL}/survey/one?id=${surveyID}`);
    setSurvey(data.data);
  };
  const [myQuestion, setMyQuestion] = useState([]);
  //GET MY QUESTIONS:
  const getMyQuestion = async (e) => {
    setMyQuestion([]);
    // console.log("getMyQuestion function called");
    let data = await axios.get(`${BASE_URL}/question?orgId=${orgId}`);
    setMyQuestion(data.data);
  };

  const gamma = localStorage.getItem("gamma");
  let sessionId = localStorage.getItem("session_id");
  let src = localStorage.getItem("srcID");
  let ref_id = localStorage.getItem("ref_id");
  const [spinnerLoader, setSpinnerLoader] = useState(false);
  const [presecreenQuestions, setPresecreenQuestions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  let [questionsData, setQuestionsData] = useState([]);
  const [qualificationTable, setQualificationTable] = useState([]);
  const [multiResponse, setMultiResponse] = useState([]);
  // !----------functions---------------------
  const handlePrescreenSubmit = (e) => {
    e.preventDefault();
  };
  const handleQuestionData = (qId, name, e) => {
    let newArr = [...questionsData]?.filter(
      (data) => data?.question_id !== qId
    );
    newArr.push({
      question_id: qId,
      question_name: name,
      user_response: e.target.value,
    });
    setQuestionsData(newArr);
  };
  const handlePrescreenEnter = (e) => {
    e.preventDefault();
    // console.log("enter called");
    // if (presecreenQuestions?.length - 1 !== currentIndex) {
    //   checkIsAnswerCorrect(e, null, "plusOne");
    // } else {
    //   checkIsAnswerCorrect(e, "lastQuestion");
    // }
  };
  const handleMultiResposnseChange = (e, id, option, index) => {
    if (e.target.checked) {
      let singArr = questionsData?.filter((data) => data.questionId === id);
      // console.log("singleArr", singArr);
      // console.log("includes", singArr[0].response.includes(index));
      if (singArr?.length) {
        if (!singArr[0].response.includes(index)) {
          singArr[0]?.response?.push(index);
        }
      } else {
        singArr = [{ questionId: id, response: [index] }];
      }
      let newArr = [...questionsData, ...singArr];
      setQuestionsData(
        [...new Set(newArr?.map(JSON.stringify))].map(JSON.parse)
      );
    } else {
      let singArr = questionsData?.filter((data) => data.questionId === id);
      let filtereRes = singArr[0]?.response?.filter((data) => data !== index);
      let filteredArray = questionsData?.filter(
        (data) => data.questionId !== id
      );
      let finalArray = [
        ...filteredArray,
        { questionId: id, response: filtereRes },
      ];
      setQuestionsData(
        [...new Set(finalArray?.map(JSON.stringify))].map(JSON.parse)
      );
    }
  };
  const getTableQualification = async (e) => {
    try {
      setQualificationConfig((prev) => ({ ...prev, loading: true }));
      setPresecreenQuestions([]);
      const questionMap = new Map(
        [...myQuestion, ...questionLibrary].map((d) => [d._id, d])
      );
      const updatedData = survey?.qualification
        ?.map((f) => {
          const question = questionMap.get(f.question);
          return { ...f, question };
        })
        .filter((d) => d?.status && d?.question);
      setPresecreenQuestions(updatedData);
      setQualificationConfig((prev) => ({ ...prev, loading: false }));
    } catch (error) {
      setQualificationConfig({ error: true, loading: false });
    }
  };

  //   !-----------------------UseEffects-----------------
  const calcProg = presecreenQuestions?.length
    ? ((currentIndex + 1) / presecreenQuestions?.length) * 100
    : 20;

  useEffect(() => {
    getSurvey();
    getMyQuestion();
  }, [orgId]);
  useEffect(() => {
    if (myQuestion && questionLibrary && survey?.qualification) {
      getTableQualification();
    }
  }, [myQuestion, questionLibrary, survey?.qualification]);
  const submitResponse = async () => {
    // console.log("submitResponse called ");
    // alert("this was the last question redirect to client live url");
    setSpinnerLoader(true);
    setTimeout(async () => {
      await axios
        .put(
          `${BASE_URL}/session?id=${sessionId}`,

          {
            responses: questionsData,
            client_status: 1,
            mirats_status: 3,
            src: src,
          }
        )
        .then(async (data) => {
          // console.log(data);
          let survey = await getCurrentSurvey(surveyID);
          let url = survey?.setup?.liveUrl;
          let testURL = survey?.setup?.testUrl;
          // console.log(url, survey);
          if (gamma !== "alpha") {
            if (url) {
              // console.log("inside if ");
              var modifiedUrl = url.replace("[%rid%]", data?.data?.ref_id);

              window.location.href = modifiedUrl;
            } else {
              // alert("Live Url is not set");
              // let url =
              //   "http://localhost:3000/tesingURLWithDynamicRid?tid=ccpArgj6&uid=[%rid%]";
              // var modifiedUrl = url.replace("[%rid%]", data.data);
              // window.location.href = modifiedUrl;
              console.log("live url is not set");
            }
          } else {
            if (testURL) {
              // console.log("inside if ");
              var modifiedUrl = testURL.replace("[%rid%]", data?.data?.ref_id);

              window.location.href = modifiedUrl;
            } else {
              // alert("Live Url is not set");
              console.log("Test url is not set");
            }
          }

          // navigate(`/${survey?.live_url}/${data.data}`);
        })
        .catch((er) => {
          console.log(er);
          setSpinnerLoader(false);
        });
    }, 0);
  };
  // console.log({
  //   lastData: presecreenQuestions[currentIndex],
  //   currentData: questionsData[questionsData?.length - 1],
  // });
  const checkIsAnswerCorrect = async (e, lastQuestion, updateState) => {
    // console.log("checkIsAnswerCorrect called ");
    let lastData = presecreenQuestions[currentIndex];
    let currentData = questionsData[questionsData?.length - 1];
    // console.log("lastData", lastData, "currentData", currentData);

    if (lastData?.question?.questionType === "Multi Punch") {
      if (currentData) {
        let allMatched = true;
        for (let i = 0; i < currentData?.user_response?.length; i++) {
          // let found = false;
          // for (let j = 0; j < lastData?.validOptions?.length; j++) {
          //   if (currentData?.user_response[i] == lastData?.validOptions[j]) {
          //     found = true;
          //     break;
          //   }
          // }
          // if (!found) {
          //   allMatched = false;
          //   break;
          // }

          allMatched = lastData?.validOptions?.some(
            (d) => d == currentData?.user_response[i]
          );
          if (allMatched) {
            break;
          }
        }
        if (allMatched) {
          // console.log("All Answer is correct");
          let qualificationQuotaFull = false;
          for (let i = 0; i < currentData?.user_response?.length; i++) {
            const isQuestionOverQuota = await axios.post(
              `${BASE_URL}/session/qualification-quota/${surveyID}/${lastData?._id}`,
              { userRes: currentData?.user_response[i] }
            );
            if (isQuestionOverQuota.data) {
              qualificationQuotaFull = true;
              break;
            }
          }
          if (!qualificationQuotaFull) {
            lastQuestion && submitResponse();
            updateState && setCurrentIndex((prev) => prev + 1);
          } else {
            await axios
              .put(`${BASE_URL}/session?id=${sessionId}`, {
                responses: questionsData,
                client_status: -1,
                mirats_status: 40,
              })
              .then((data) => {
                // console.log(data);
                window.location.href = `${
                  window.location.origin
                }/7e08091a73b14e034889265e41ba796f91c766ad/${encodeURIComponent(
                  ref_id
                )}/40`;
              })
              .catch((er) => console.log(er));
          }
        } else {
          // alert("You have been terminated !!");
          // console.log("Answer is wrong");
          await axios
            .put(`${BASE_URL}/session?id=${sessionId}`, {
              responses: questionsData,
              client_status: -1,
              mirats_status: 23,
            })
            .then((data) => {
              // console.log(data);

              window.location.href = `${
                window.location.origin
              }/7e08091a73b14e034889265e41ba796f91c766ad/${encodeURIComponent(
                ref_id
              )}/20`;
            })
            .catch((er) => console.log(er));
        }
      } else {
        // console.log("No answers in prescreen");
      }
    } else if (lastData?.question?.questionType === "Single Punch") {
      if (lastData?.validOptions?.includes(currentData?.user_response)) {
        const isQuestionOverQuota = await axios.post(
          `${BASE_URL}/session/qualification-quota/${surveyID}/${lastData?._id}`,
          { userRes: currentData?.user_response }
        );
        if (!isQuestionOverQuota.data) {
          lastQuestion && submitResponse();
          updateState && setCurrentIndex((prev) => prev + 1);
        } else {
          // console.log("isQuestionOverQuota=>", isQuestionOverQuota);
          await axios
            .put(`${BASE_URL}/session?id=${sessionId}`, {
              responses: questionsData,
              client_status: -1,
              mirats_status: 40,
            })
            .then((data) => {
              // console.log(data);
              window.location.href = `${
                window.location.origin
              }/7e08091a73b14e034889265e41ba796f91c766ad/${encodeURIComponent(
                ref_id
              )}/40`;
            })
            .catch((er) => console.log(er));
        }
      } else {
        // alert("You have been terminated !!");
        // console.log("Answer is wrong");
        await axios
          .put(`${BASE_URL}/session?id=${sessionId}`, {
            responses: questionsData,
            client_status: -1,
            mirats_status: 23,
          })
          .then((data) => {
            // console.log(data);
            window.location.href = `${
              window.location.origin
            }/7e08091a73b14e034889265e41ba796f91c766ad/${encodeURIComponent(
              ref_id
            )}/20`;
          })
          .catch((er) => console.log(er));
      }
    } else if (lastData?.question?.questionType === "Numeric - Open-end") {
      if (
        Number(currentData?.user_response) >=
          Number(lastData?.validOptions?.[0]?.from) &&
        Number(currentData?.user_response) <= lastData?.validOptions?.[0]?.to
      ) {
        // console.log(" answer is correct");

        lastQuestion && submitResponse();
        updateState && setCurrentIndex((prev) => prev + 1);
      } else {
        // alert("You have been terminated !!");
        // console.log("Answer is wrong");
        await axios
          .put(`${BASE_URL}/session?id=${sessionId}`, {
            responses: questionsData,
            client_status: -1,
            mirats_status: 23,
          })
          .then((data) => {
            // console.log(data);

            window.location.href = `${
              window.location.origin
            }/7e08091a73b14e034889265e41ba796f91c766ad/${encodeURIComponent(
              ref_id
            )}/20`;
          })
          .catch((er) => console.log(er));
      }
    } else {
      lastQuestion && submitResponse();
      updateState && setCurrentIndex((prev) => prev + 1);
    }
  };
  // console.log(presecreenQuestions);
  // console.log(survey);
  // console.log({ myQuestion, questionLibrary });
  // console.log(questionsData);
  return (
    <div className="h-[100dvh] relative">
      <div className="w-full h-[10px] bg-[#EAECF2]  rounded-[24px] ">
        <div
          style={{ width: `${calcProg}%` }}
          className={` bg-primary h-[10px]  rounded-[24px] myTransition`}
        >
          {/* <span>{calcProg}%</span> */}
        </div>
      </div>
      <div className="pt-[70px] h-full ipad:py-[44px]">
        <img
          src="https://esomar.mirats.in/unnamed.png"
          alt="logo"
          className="w-[160px] mx-[150px] ipad:ml-6"
        />
        <section className="mt-[80px] ipad:mt-[50px] pb-5">
          <form
            action=""
            onSubmit={(e) => {
              // console.log("qweoiuwqeo");
              e.preventDefault();
              // console.log("onSubmit");
              if (presecreenQuestions?.length - 1 !== currentIndex) {
                checkIsAnswerCorrect(e, null, "plusOne");
              } else {
                checkIsAnswerCorrect(e, "lastQuestion");
              }
            }}
          >
            {qualificationConfig?.loading ? (
              <div className="mx-[150px] mt-[20px] tablet:mt-[50px] tablet:mx-[30px] ipad:mx-[24px] space-y-4">
                <Skeleton className="w-[450px] h-[40px]" />
                <div className="space-y-3">
                  {[1, 2, 3, 4, 5]?.map((d) => (
                    <div key={d} className="flex items-center gap-4">
                      <Skeleton className="w-[30px] h-[30px] rounded-full" />
                      <Skeleton className="w-[140px] h-[30px]" />
                    </div>
                  ))}
                </div>
              </div>
            ) : qualificationConfig?.error ? (
              <div className="mx-[150px] mt-[20px] tablet:mt-[50px] tablet:mx-[30px] ipad:mx-[24px] space-y-4">
                <h1 className="text-lg">Something went wrong..</h1>
              </div>
            ) : (
              <div className="">
                {presecreenQuestions?.map((element, index) => {
                  if (index === currentIndex) {
                    if (element?.question?.questionType == "Single Punch") {
                      return SinglePunch({
                        element,
                        handleQuestionData,
                        questionsData,
                        setQuestionsData,
                        handlePrescreenEnter,
                      });
                    }
                    if (element?.question?.questionType === "Text - Open-end") {
                      return TextEnd({
                        element,
                        handleQuestionData,
                        handlePrescreenEnter,
                      });
                    }
                    if (
                      element?.question?.questionType === "Numeric - Open-end"
                    ) {
                      return NumericOpenEnd({
                        element,
                        handleQuestionData,
                        handlePrescreenEnter,
                      });
                    }
                    if (element?.question?.questionType === "Multi Punch") {
                      return MultiPunch({
                        element,
                        handleQuestionData,
                        multiResponse,
                        setMultiResponse,
                        handleMultiResposnseChange,
                        handlePrescreenEnter,
                        questionsData,
                        setQuestionsData,
                      });
                    }
                  }
                })}
                <section className=" mx-[150px] mt-[20px] tablet:mt-[50px] tablet:mx-[30px] ipad:mx-[24px] submitQualificationContainer">
                  {spinnerLoader ? (
                    <LoaderBTN />
                  ) : presecreenQuestions?.length - 1 !== currentIndex ? (
                    <button
                      type="submit"
                      className="bg-primary hover:bg-[#1e64fb] px-4 py-3 text-white font-semibold rounded w-[118px] text-[14px] "
                    >
                      Next{" "}
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="bg-primary hover:bg-[#1e64fb] px-4 py-3 text-white font-semibold rounded w-[118px] text-[14px] "
                    >
                      Submit
                    </button>
                  )}
                </section>
              </div>
            )}
          </form>
        </section>
      </div>
    </div>
  );
};
const TextEnd = ({ element, handleQuestionData, handlePrescreenEnter }) => {
  // console.log("inside text");
  return (
    <div className="px-[150px] ipad:px-[24px] h-[65dvh] ipad:h-[55dvh]">
      <section className="flex flex-col gap-y-3 h-[50dvh] ipad:h-[45dvh]  overflow-y-auto ">
        <label htmlFor="age" className="text-[24px] font-[500] capitalize">
          {element?.question?.questionText}
        </label>
        <input
          type="text"
          name="age"
          onChange={(e) =>
            handleQuestionData(element?._id, element?.question?.questionName, e)
          }
          required
          className="border border-[#333] text-[14px] text-[#333] py-3 px-4 outline-none rounded w-[500px] textEnd ipad:w-[100%]"
        />
      </section>
    </div>
  );
};
const NumericOpenEnd = ({
  element,
  handleQuestionData,
  handlePrescreenEnter,
}) => {
  // console.log("inside numeric");
  return (
    <div className="px-[150px] ipad:px-[24px] h-[65dvh] ipad:h-[55dvh]">
      <section className="flex flex-col gap-y-3 h-[50dvh] ipad:h-[45dvh]  overflow-y-auto ">
        <label htmlFor="age" className="text-[24px] font-[500] capitalize">
          {element?.question?.questionText}
        </label>
        <input
          type="number"
          name="age"
          required
          className="border border-[#333] text-[14px] text-[#333] py-3 px-4 outline-none rounded w-[500px] textEnd ipad:w-[100%]"
          onChange={(e) =>
            handleQuestionData(element?._id, element?.question?.questionName, e)
          }
        />
      </section>
    </div>
  );
};
const SinglePunch = ({
  element,
  handleQuestionData,
  handlePrescreenEnter,
  questionsData,
  setQuestionsData,
}) => {
  const handleSingleChange = (qId, e, ind, data) => {
    // console.log("QID=>", qId, "Ind", ind);
    let newArr = [...questionsData]?.filter(
      (data) => data?.question_id !== qId
    );
    newArr.push({
      question_id: qId,
      question_name: element?.question?.questionName,
      user_response: data,
    });
    setQuestionsData(newArr);
  };

  return (
    <div className="px-[150px] ipad:px-[24px] h-[65dvh] ipad:h-[55dvh]">
      <h1 className="mb-[39px] text-[24px] capitalize ipad:text-[20px] ">
        {element?.question?.questionText}
      </h1>
      <section className="flex flex-col gap-y-3 h-[50dvh] ipad:h-[40dvh]  overflow-y-auto singlePunchContainer">
        {element?.displayOptions?.map((data, index) => (
          <>
            <div class="flex items-center cursor-pointer singlePunchOption">
              <label className="container capitalize">
                {data}
                <input
                  type="radio"
                  // checked="checked"
                  required
                  name="singlePunch"
                  id={data}
                  onChange={(e) => {
                    handleSingleChange(element?._id, e, index, data);
                  }}
                />
                <span className="radio"></span>
              </label>
            </div>
          </>
        ))}
      </section>
    </div>
  );
};

const MultiPunch = ({
  element,
  handleQuestionData,
  multiResponse,
  setMultiResponse,
  handlePrescreenEnter,
  questionsData,
  setQuestionsData,
}) => {
  // console.log("multipunch");
  // console.log(element);
  // console.log(questionsData);
  const handleMultiResposnseChange = (e, id, option, index) => {
    if (e.target.checked) {
      let singArr = questionsData?.filter((data) => data.question_id === id);
      // console.log("singleArr", singArr);
      // console.log("includes", singArr[0]?.user_response?.includes(index));
      if (singArr?.length) {
        if (!singArr[0].user_response.includes(option)) {
          singArr[0]?.user_response?.push(option);
        }
      } else {
        singArr = [
          {
            question_id: id,
            question_name: element?.name,
            user_response: [option],
          },
        ];
      }
      let newArr = [...questionsData, ...singArr];
      setQuestionsData(
        [...new Set(newArr?.map(JSON.stringify))].map(JSON.parse)
      );
    } else {
      let singArr = questionsData?.filter((data) => data.question_id === id);
      let filtereRes = singArr[0]?.user_response?.filter(
        (data) => data !== option
      );
      let filteredArray = questionsData?.filter(
        (data) => data.question_id !== id
      );
      let finalArray = [
        ...filteredArray,
        { question_id: id, user_response: filtereRes },
      ];
      setQuestionsData(
        [...new Set(finalArray?.map(JSON.stringify))].map(JSON.parse)
      );
    }
  };
  let testMulti = 0;

  return (
    <div className="px-[150px] ipad:px-[24px] h-[65dvh] ipad:h-[55dvh]">
      <h1 className="mb-[39px] text-[24px] ipad:text-[20px] capitalize">
        {element?.question?.questionText}
      </h1>
      <section className="flex flex-col gap-y-3 h-[45dvh] ipad:h-[40dvh]  overflow-y-auto singlePunchContainer">
        {element?.displayOptions?.map((option, index) => (
          <>
            <div class="flex cursor-pointer multiPunchOption">
              <label className="containerCheck capitalize">
                {option}
                <input
                  type="checkbox"
                  id={index}
                  required={
                    !questionsData?.some(
                      (cData) =>
                        cData?.question_id == element?._id &&
                        cData?.user_response?.length >= 1
                    )
                  }
                  name="multiPunch"
                  value={option}
                  onChange={(e) =>
                    handleMultiResposnseChange(e, element?._id, option, index)
                  }
                />
                <span className="check"></span>
              </label>
            </div>
          </>
        ))}
      </section>
    </div>
  );
};

export default SurveyQualification;
