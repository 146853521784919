import React, { useEffect, useState } from "react";
import { AiOutlineSearch, AiOutlineEye } from "react-icons/ai";
import { MdOutlineModeEditOutline, MdDeleteOutline } from "react-icons/md";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { RxCross2 } from "react-icons/rx";
import { useGlobalContext } from "../../../../Contexts/GlobalContext";
import axios from "axios";
import { BASE_URL } from "../../../../Utlils";
import DeleteModal from "../../../../components/DeleteModal";
import { IoMdAddCircleOutline } from "react-icons/io";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import Required from "../../../../components/Required";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "#F9F9FF",
  boxShadow: 24,
  borderRadius: 2,
};

const firstModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "452px",
  bgcolor: "#F9F9FF",
  boxShadow: 24,
  borderRadius: 2,
};

const Qualification = ({ data, type, id, getSingleSurvey }) => {
  const [addLogicModal, setAddLogicModal] = useState({
    firstModal: false,
    secondModal: false,
  });
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({ qualification: false, logic: false });
  const { myQuestion, questionLibrary, setSnackbar } = useGlobalContext();
  let allQuestions = [...myQuestion, ...questionLibrary];
  const [tab, setTab] = useState(0);
  const [deleteM, setDeleteM] = useState({ open: false });
  const [previewData, setPreviewData] = useState({});
  const [filters, setFilters] = useState({});
  const [edit, setEdit] = useState({ isEdit: false, data: {} });
  const [qualificationTable, setQualificationTable] = useState([]);
  const handleOpen = (name) => setModal((prev) => ({ [name]: true }));
  const handleClose = (name) => {
    setModal((prev) => ({ [name]: false }));
    setEdit({});
    setTab(0);
  };

  const getTableQualification = async (e) => {
    setQualificationTable([]);
    const questionMap = new Map(
      [...myQuestion, ...questionLibrary].map((d) => [d._id, d])
    );
    const updatedTable = data?.qualification
      ?.map((f) => {
        const question = questionMap.get(f.question);
        return { ...f, question };
      })
      .filter((item) => item.question);

    setQualificationTable(updatedTable);
  };

  const toggleQualificationStatus = async (qualificationID) => {
    try {
      let data = await axios.put(
        `${BASE_URL}/survey/toggle-qualification-status/${id}/${qualificationID}`
      );
      await getSingleSurvey(id);
      setSnackbar({
        open: true,
        msg: "Qualification status updated !",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        msg: "something went wrong !",
        severity: "error",
      });
    }
  };
  // console.log(data);
  useEffect(() => {
    if (myQuestion && questionLibrary && data?.qualification) {
      getTableQualification();
    }
  }, [myQuestion, questionLibrary, data]);

  const MultiPunch = ({ allQuestions, edit, getTableQualification }) => {
    const [question, setQuestion] = useState(
      edit?.isEdit ? edit?.data?.question : {}
    );
    const [selectedQuestion, setSelectedQuestion] = useState({
      displayOption: edit?.isEdit ? edit?.data?.displayOptions : [],
      validAnswer: edit?.isEdit ? edit?.data?.validOptions : [],
    });
    const [condition, setCondition] = useState({
      min: edit?.isEdit ? edit?.data?.condition?.min : "",
      max: edit?.isEdit ? edit?.data?.condition?.max : "",
    });

    //ADD DATA:
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      // extra code only for edit functionality from here to
      const updatedQualification = [...data.qualification];
      const indexToEdit = updatedQualification.findIndex(
        (fd) => fd._id === edit?.data?._id
      );
      if (indexToEdit !== -1) {
        updatedQualification[indexToEdit] = {
          question: question?._id,
          displayOptions: selectedQuestion?.displayOption,
          validOptions: selectedQuestion?.validAnswer,
          condition,
          _id: edit?.data?._id,
        };
      }
      //extra code  for edit ends here
      await axios
        .put(`${BASE_URL}/survey?id=${id}`, {
          qualification: !edit?.isEdit
            ? [
                ...data?.qualification,
                {
                  question: question?._id,
                  displayOptions: selectedQuestion?.displayOption,
                  validOptions: selectedQuestion?.validAnswer,
                  condition,
                },
              ]
            : updatedQualification,
        })
        .then((res) => {
          setLoading(false);
          getSingleSurvey();
          getTableQualification();
          handleClose("qualification");

          setSnackbar((prev) => ({
            ...prev,
            msg: !edit?.isEdit
              ? "Multi Punch Qualification Added !"
              : "Multi Punch Qualification Updated !",
            open: true,
            severity: "success",
          }));
        })
        .catch((er) => {
          setLoading(false);
          handleClose("qualification");
          setSnackbar((prev) => ({
            ...prev,
            msg: "Something went wrong !",
            open: true,
            severity: "error",
          }));
        });
    };
    const handleConditionChange = (e) => {
      let { name, value } = e.target;
      setCondition((prev) => ({ ...prev, [name]: Number(value) }));
    };
    // console.log(data);
    // console.log(allQuestions);
    // console.log(question);
    // console.log(selectedQuestion);
    // console.log(edit);
    return (
      <form
        className="w-full px-5 pt-8 flex flex-col gap-[31px]"
        onSubmit={handleSubmit}
      >
        <div className="w-full flex flex-col gap-[20px] ">
          <div>
            <h1 className="text-[#000] text-[14px] font-[500]">
              Select Question
            </h1>

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              className="questionAutoComplete"
              value={question?.questionText}
              options={allQuestions
                ?.filter((fdata) => fdata?.questionType == "Multi Punch")
                ?.filter((d) => {
                  return !edit?.isEdit
                    ? !data?.qualification?.find((q) => q?.question === d?._id)
                    : d;
                })
                ?.map((mdata, ind) => {
                  return {
                    label: mdata?.questionText,
                    value: mdata?._id,
                  };
                })}
              renderInput={(params) => <TextField {...params} />}
              disableClearable
              onChange={(event, newValue) => {
                setQuestion(
                  allQuestions?.find((data) => data?._id == newValue?.value)
                );
                setSelectedQuestion((prev) => ({
                  displayOption: [],
                  validAnswer: [],
                }));
              }}
            />
          </div>

          <div className="w-full border border-[#E0E0E0]">
            <div className="w-full px-5 py-2 flex items-center justify-between border-b border-[#E0E0E0]">
              <h1 className="w-1/3 text-[14px] font-[500] text-[#000]">
                Display Options
              </h1>
              <h1 className="w-1/3 text-[14px] font-[500] text-[#000]">
                One or More From
              </h1>
              <h1 className="w-1/3 text-[14px] font-[500] text-[#000]">
                Selected Options
              </h1>
            </div>
            <div className="w-full px-5 flex gap-[10px] justify-between overflow-y-auto containerScroll max-h-[300px]">
              <div className="w-1/3 break-words flex flex-col gap-[5px]">
                {Object.keys(question)?.length ? (
                  <div className="w-full pr-2 flex items-center gap-[16px] my-2 ">
                    <input
                      type="checkbox"
                      id="selectAll1"
                      name="selectAll1"
                      checked={
                        question?.options?.length ==
                        selectedQuestion?.displayOption?.length
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedQuestion((prev) => ({
                            ...prev,
                            displayOption: question?.options,
                          }));
                        } else {
                          setSelectedQuestion((prev) => ({
                            ...prev,
                            displayOption: [],
                            validAnswer: [],
                          }));
                        }
                      }}
                      className="w-[10%] h-[30px]"
                    />
                    <label
                      htmlFor="selectAll1"
                      className="text-[14px] font-[400] text-[#000] w-[90%] cursor-pointer"
                    >
                      Select All
                    </label>
                  </div>
                ) : (
                  ""
                )}
                {question?.options?.map((data) => (
                  <div className="w-full pr-2 flex items-center gap-[16px] my-2 ">
                    <input
                      type="checkbox"
                      name={data}
                      id={data}
                      required={
                        selectedQuestion?.displayOption.length ? false : true
                      }
                      checked={selectedQuestion?.displayOption?.includes(data)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedQuestion((prev) => ({
                            ...prev,
                            displayOption: [...prev?.displayOption, data],
                          }));
                        } else {
                          setSelectedQuestion((prev) => ({
                            ...prev,
                            displayOption: prev?.displayOption?.filter(
                              (fdata) => fdata !== data
                            ),
                            validAnswer: prev?.validAnswer?.filter(
                              (fdata) => fdata !== data
                            ),
                          }));
                        }
                      }}
                      className="w-[10%] h-[30px]"
                    />
                    <label
                      htmlFor={data}
                      className="text-[14px] font-[400] text-[#000] w-[90%] cursor-pointer"
                    >
                      {data}
                    </label>
                  </div>
                ))}
              </div>
              <div className="w-1/3 break-words flex flex-col gap-[5px]">
                {selectedQuestion?.displayOption?.length ? (
                  <div className="w-full pr-2 flex items-center  gap-[16px] my-2 ">
                    <input
                      type="checkbox"
                      id="selectAll2"
                      name="selectAll2"
                      checked={
                        selectedQuestion?.displayOption?.length ==
                          selectedQuestion?.validAnswer?.length &&
                        selectedQuestion?.validAnswer?.length !== 0
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedQuestion((prev) => ({
                            ...prev,
                            validAnswer: selectedQuestion?.displayOption,
                          }));
                        } else {
                          setSelectedQuestion((prev) => ({
                            ...prev,
                            validAnswer: [],
                          }));
                        }
                      }}
                      className="w-[10%] h-[30px]"
                    />
                    <label
                      htmlFor="selectAll2"
                      className="text-[14px] font-[400] text-[#000] w-[90%] cursor-pointer"
                    >
                      Select All
                    </label>
                  </div>
                ) : (
                  ""
                )}
                {selectedQuestion?.displayOption?.map((data, ind) => (
                  <div className="w-full pr-2 flex items-center  gap-[18px] my-2 ">
                    <input
                      type="checkbox"
                      name={data}
                      id={data + ind}
                      required={
                        selectedQuestion?.validAnswer?.length ? false : true
                      }
                      checked={selectedQuestion?.validAnswer?.includes(data)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedQuestion((prev) => ({
                            ...prev,
                            validAnswer: [...prev?.validAnswer, data],
                          }));
                        } else {
                          setSelectedQuestion((prev) => ({
                            ...prev,
                            validAnswer: prev?.validAnswer?.filter(
                              (fdata) => fdata !== data
                            ),
                          }));
                        }
                      }}
                      className="w-[10%] h-[30px]"
                    />
                    <label
                      htmlFor={data + ind}
                      className="text-[14px] font-[400] text-[#000] w-[90%] cursor-pointer"
                    >
                      {data}
                    </label>
                  </div>
                ))}
              </div>
              <div className="w-1/3 break-words flex flex-col gap-[18px]">
                {selectedQuestion?.validAnswer?.map((data) => (
                  <div className="w-full pr-2 flex items-center  gap-[18px] mt-2 ">
                    <h1 className="bg-[#0226BE26] text-[12px] text-[#0226BE] border border-[#0226BE] py-[4px] px-[8px]">
                      {data}
                    </h1>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="flex justify-center w-full">
            <CircularProgress className="text-center" size={23} />
          </div>
        ) : (
          <div className="w-full flex items-center justify-center">
            <button
              type="submit"
              className="text-[#fff] inter text-[16px] font-[400] bg-[#0226BE] rounded-[4px] py-[8px] px-[16px]"
            >
              Set Qualification
            </button>
          </div>
        )}
      </form>
    );
  };

  const SinglePunch = ({ allQuestions, edit, getTableQualification }) => {
    const [question, setQuestion] = useState(
      edit?.isEdit ? edit?.data?.question : {}
    );
    const [selectedQuestion, setSelectedQuestion] = useState({
      displayOption: edit?.isEdit ? edit?.data?.displayOptions : [],
      validAnswer: edit?.isEdit ? edit?.data?.validOptions : [],
    });
    const [condition, setCondition] = useState({
      min: edit?.isEdit ? edit?.data?.condition?.min : "",
      max: edit?.isEdit ? edit?.data?.condition?.max : "",
    });
    const [loading, setLoading] = useState(false);

    // console.log("data?.qualification=>", data?.qualification);
    //ADD SINGLE PUNCH DATA:
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      // extra code only for edit functionality from here to
      const updatedQualification = [...data.qualification];
      const indexToEdit = updatedQualification.findIndex(
        (fd) => fd._id === edit?.data?._id
      );
      if (indexToEdit !== -1) {
        updatedQualification[indexToEdit] = {
          question: question?._id,
          displayOptions: selectedQuestion?.displayOption,
          validOptions: selectedQuestion?.validAnswer,
          condition,
          _id: edit?.data?._id,
        };
      }
      //extra code  for edit ends here
      await axios
        .put(`${BASE_URL}/survey?id=${id}`, {
          qualification: !edit?.isEdit
            ? [
                ...data?.qualification,
                {
                  question: question?._id,
                  displayOptions: selectedQuestion?.displayOption,
                  validOptions: selectedQuestion?.validAnswer,
                  condition,
                },
              ]
            : updatedQualification,
        })
        .then((res) => {
          setLoading(false);
          handleClose("qualification");
          getSingleSurvey();
          getTableQualification();
          setSnackbar((prev) => ({
            ...prev,
            msg: !edit?.isEdit
              ? "Single Punch Qualification Added !"
              : "Single Punch Qualification Updated !",
            open: true,
            severity: "success",
          }));
        })
        .catch((er) => {
          setLoading(false);
          // console.log("er", er);
          handleClose("qualification");
          setSnackbar((prev) => ({
            ...prev,
            msg: "Something went wrong !",
            open: true,
            severity: "error",
          }));
        });
    };
    const handleConditionChange = (e) => {
      let { name, value } = e.target;
      setCondition((prev) => ({ ...prev, [name]: Number(value) }));
    };
    return (
      <form
        className="w-full px-5 pt-8 flex flex-col gap-[31px]"
        onSubmit={handleSubmit}
      >
        <div className="w-full flex flex-col gap-[20px] ">
          <div>
            <h1 className="text-[#000] text-[14px] font-[500]">
              Select Question
            </h1>

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              className="questionAutoComplete"
              disableClearable
              value={question?.questionText}
              options={allQuestions
                ?.filter((data) => data?.questionType == "Single Punch")
                ?.filter((d) => {
                  return !edit?.isEdit
                    ? !data?.qualification?.find((q) => q?.question === d?._id)
                    : d;
                })
                ?.map((data, ind) => {
                  return {
                    label: data?.questionText,
                    value: data?._id,
                  };
                })}
              renderInput={(params) => <TextField {...params} />}
              onChange={(event, newValue) => {
                setQuestion(
                  allQuestions?.find((data) => data?._id == newValue?.value)
                );
                setSelectedQuestion((prev) => ({
                  displayOption: [],
                  validAnswer: [],
                }));
              }}
            />
          </div>
          <div className="w-full border border-[#E0E0E0]">
            <div className="w-full px-5 py-2 flex items-center justify-between border-b border-[#E0E0E0]">
              <h1 className="w-1/3 text-[14px] font-[500] text-[#000]">
                Display Options
              </h1>
              <h1 className="w-1/3 text-[14px] font-[500] text-[#000]">
                One or More From
              </h1>
              <h1 className="w-1/3 text-[14px] font-[500] text-[#000]">
                Selected Options
              </h1>
            </div>
            <div className="w-full px-5 flex  justify-between overflow-y-auto containerScroll max-h-[300px]">
              <div className="w-1/3 break-words flex flex-col gap-[5px]">
                {Object.keys(question)?.length ? (
                  <div className="w-full pr-2 flex items-center gap-[16px] my-2 ">
                    <input
                      type="checkbox"
                      id="selectAll2"
                      name="selectAll2"
                      checked={
                        question?.options?.length ==
                        selectedQuestion?.displayOption?.length
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedQuestion((prev) => ({
                            ...prev,
                            displayOption: question?.options,
                          }));
                        } else {
                          setSelectedQuestion((prev) => ({
                            ...prev,
                            displayOption: [],
                            validAnswer: [],
                          }));
                        }
                      }}
                      className="w-[10%] h-[30px]"
                    />
                    <label
                      htmlFor="selectAll2"
                      className="text-[14px] font-[400] text-[#000] w-[90%] cursor-pointer"
                    >
                      Select All
                    </label>
                  </div>
                ) : (
                  ""
                )}
                {question?.options?.map((data) => (
                  <div className="w-full pr-2 flex items-center gap-[16px] my-2 ">
                    <input
                      type="checkbox"
                      name="select2"
                      id={data}
                      required={
                        selectedQuestion?.displayOption?.length ? false : true
                      }
                      checked={selectedQuestion?.displayOption?.includes(data)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedQuestion((prev) => ({
                            ...prev,
                            displayOption: [...prev?.displayOption, data],
                          }));
                        } else {
                          setSelectedQuestion((prev) => ({
                            ...prev,
                            displayOption: prev?.displayOption?.filter(
                              (fdata) => fdata !== data
                            ),
                            validAnswer: prev?.validAnswer?.filter(
                              (fdata) => fdata !== data
                            ),
                          }));
                        }
                      }}
                      className="w-[10%] h-[30px]"
                    />
                    <label
                      htmlFor={data}
                      className="text-[14px] font-[400] text-[#000] w-[90%] cursor-pointer"
                    >
                      {data}
                    </label>
                  </div>
                ))}
              </div>
              <div className="w-1/3 break-words flex flex-col gap-[5px]">
                {selectedQuestion?.displayOption?.length ? (
                  <div className="w-full pr-2 flex items-center  gap-[16px] my-2 ">
                    <input
                      type="checkbox"
                      id="selectAll1"
                      checked={
                        selectedQuestion?.displayOption?.length ==
                          selectedQuestion?.validAnswer?.length &&
                        selectedQuestion?.validAnswer?.length !== 0
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedQuestion((prev) => ({
                            ...prev,
                            validAnswer: selectedQuestion?.displayOption,
                          }));
                        } else {
                          setSelectedQuestion((prev) => ({
                            ...prev,
                            validAnswer: [],
                          }));
                        }
                      }}
                      className="w-[10%] h-[30px]"
                    />
                    <label
                      htmlFor="selectAll1"
                      className="text-[14px] font-[400] text-[#000] w-[90%] cursor-pointer"
                    >
                      Select All
                    </label>
                  </div>
                ) : (
                  ""
                )}
                {selectedQuestion?.displayOption?.map((data, ind) => (
                  <div className="w-full pr-2 flex items-center  gap-[18px] my-2 ">
                    <input
                      type="checkbox"
                      name={data}
                      id={data + ind}
                      required={
                        selectedQuestion?.validAnswer?.length ? false : true
                      }
                      checked={selectedQuestion?.validAnswer?.includes(data)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedQuestion((prev) => ({
                            ...prev,
                            validAnswer: [...prev?.validAnswer, data],
                          }));
                        } else {
                          setSelectedQuestion((prev) => ({
                            ...prev,
                            validAnswer: prev?.validAnswer?.filter(
                              (fdata) => fdata !== data
                            ),
                          }));
                        }
                      }}
                      className="w-[10%] h-[30px]"
                    />
                    <label
                      htmlFor={data + ind}
                      className="text-[14px] font-[400] text-[#000] w-[90%] cursor-pointer"
                    >
                      {data}
                    </label>
                  </div>
                ))}
              </div>
              <div className="w-1/3 break-words flex flex-col gap-[18px]">
                {selectedQuestion?.validAnswer?.map((data) => (
                  <div className="w-full pr-2 flex items-center  gap-[18px] mt-2 ">
                    <h1 className="bg-[#0226BE26] text-[12px] text-[#0226BE] border border-[#0226BE] py-[4px] px-[8px]">
                      {data}
                    </h1>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="flex justify-center w-full">
            <CircularProgress className="text-center" size={23} />
          </div>
        ) : (
          <div className="w-full flex items-center justify-center">
            <button
              type="submit"
              className="text-[#fff] inter text-[16px] font-[400] bg-[#0226BE] rounded-[4px] py-[8px] px-[16px]"
            >
              Set Qualification
            </button>
          </div>
        )}
      </form>
    );
  };

  const Numeric = ({ allQuestions, edit, getTableQualification }) => {
    const [question, setQuestion] = useState(
      edit?.isEdit ? edit?.data?.question : {}
    );
    const [validOptions, setValidOptions] = useState(
      edit?.isEdit ? edit?.data?.validOptions : [{ from: "", to: "" }]
    );
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
      e.preventDefault();
      // extra code only for edit functionality from here to
      const updatedQualification = [...data.qualification];
      const indexToEdit = updatedQualification.findIndex(
        (fd) => fd._id === edit?.data?._id
      );
      if (indexToEdit !== -1) {
        updatedQualification[indexToEdit] = {
          question: question?._id,
          validOptions: validOptions,
          _id: edit?.data?._id,
        };
      }
      //extra code  for edit ends here
      try {
        setLoading(true);
        await axios
          .put(`${BASE_URL}/survey?id=${id}`, {
            qualification: !edit?.isEdit
              ? [
                  ...data?.qualification,
                  {
                    question: question?._id,
                    validOptions: validOptions,
                  },
                ]
              : updatedQualification,
          })
          .then((res) => {
            setLoading(false);
            handleClose("qualification");
            getSingleSurvey();
            getTableQualification();
            setSnackbar((prev) => ({
              ...prev,
              msg: "Multi Punch Qualification Added !",
              open: true,
              severity: "success",
            }));
          })
          .catch((er) => {
            setLoading(false);
            handleClose("qualification");
            setSnackbar((prev) => ({
              ...prev,
              msg: "Something went wrong !",
              open: true,
              severity: "error",
            }));
          });
      } catch (error) {
        setLoading(false);
        handleClose("qualification");
        setSnackbar((prev) => ({
          ...prev,
          msg: "Something went wrong !",
          open: true,
          severity: "error",
        }));
      }
    };

    // console.log(validOptions);
    return (
      <form
        className="w-full px-5 pt-8 flex flex-col gap-[31px]"
        onSubmit={handleSubmit}
      >
        <div className="w-full flex flex-col gap-[20px] ">
          <div>
            <h1 className="text-[#000] text-[14px] font-[500]">
              Select Question
            </h1>

            <Autocomplete
              disableClearable
              disablePortal
              id="combo-box-demo"
              className="questionAutoComplete"
              value={question?.questionText}
              options={allQuestions
                ?.filter((data) => data?.questionType == "Numeric - Open-end")
                ?.filter((d) => {
                  return !edit?.isEdit
                    ? !data?.qualification?.find((q) => q?.question === d?._id)
                    : d;
                })
                ?.map((data, ind) => {
                  return {
                    label: data?.questionText,
                    value: data?._id,
                  };
                })}
              renderInput={(params) => <TextField {...params} />}
              onChange={(event, newValue) => {
                setValidOptions([{ from: "", to: "" }]);
                setQuestion(
                  allQuestions?.find((data) => data?._id == newValue.value)
                );
              }}
            />
          </div>
          <div className="w-full ">
            <h1 className="text-[#000] text-[14px] font-[500]">
              Allow Responses
            </h1>
            <div className="w-[40%] flex gap-[19px]">
              <input
                type="number"
                placeholder="From"
                required
                value={validOptions?.[0]?.from}
                onChange={(e) => {
                  let data = [...validOptions];

                  data[0].from = Number(e.target.value);
                  setValidOptions(data);
                }}
                className="w-full border border-[#E0E0E0]  rounded-[6.5px] pl-3 text-[#666] font-[400] text-[12px] h-[40px] mt-3 bg-[#F9F9FF]"
              />
              <input
                type="number"
                placeholder="To"
                required
                value={validOptions?.[0]?.to}
                onChange={(e) => {
                  let data = [...validOptions];
                  data[0].to = Number(e.target.value);
                  setValidOptions(data);
                }}
                className="w-full border border-[#E0E0E0]  rounded-[6.5px] pl-3 text-[#666] font-[400] text-[12px] h-[40px] mt-3 bg-[#F9F9FF]"
              />
            </div>
          </div>
        </div>
        {loading ? (
          <div className="flex justify-center w-full">
            <CircularProgress className="text-center" size={23} />
          </div>
        ) : (
          <div className="w-full flex items-center justify-center">
            <button
              type="submit"
              className="text-[#fff] inter text-[16px] font-[400] bg-[#0226BE] rounded-[4px] py-[8px] px-[16px]"
            >
              Set Qualification
            </button>
          </div>
        )}
      </form>
    );
  };

  const Text = ({ allQuestions, edit, getTableQualification }) => {
    const [question, setQuestion] = useState(
      edit?.isEdit ? edit?.data?.question : {}
    );
    const [validOptions, setValidOptions] = useState(
      edit?.isEdit ? edit?.data?.validOptions[0] : ""
    );
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
      e.preventDefault();
      // extra code only for edit functionality from here to
      const updatedQualification = [...data.qualification];
      const indexToEdit = updatedQualification.findIndex(
        (fd) => fd._id === edit?.data?._id
      );
      if (indexToEdit !== -1) {
        updatedQualification[indexToEdit] = {
          question: question?._id,
          validOptions: [validOptions],
          _id: edit?.data?._id,
        };
      }
      //extra code  for edit ends here
      try {
        setLoading(true);
        await axios
          .put(`${BASE_URL}/survey?id=${id}`, {
            qualification: !edit?.isEdit
              ? [
                  ...data?.qualification,
                  {
                    question: question?._id,
                    validOptions: [validOptions],
                  },
                ]
              : updatedQualification,
          })
          .then((res) => {
            setLoading(false);
            handleClose("qualification");
            getSingleSurvey();
            getTableQualification();
            setSnackbar((prev) => ({
              ...prev,
              msg: "Multi Punch Qualification Added !",
              open: true,
              severity: "success",
            }));
          })
          .catch((er) => {
            setLoading(false);
            handleClose("qualification");
            setSnackbar((prev) => ({
              ...prev,
              msg: "Something went wrong !",
              open: true,
              severity: "error",
            }));
          });
      } catch (error) {
        setLoading(false);
        handleClose("qualification");
        setSnackbar((prev) => ({
          ...prev,
          msg: "Something went wrong !",
          open: true,
          severity: "error",
        }));
        // console.log(error);
      }
    };

    return (
      <form
        className="w-full px-5 pt-8 flex flex-col gap-[31px]"
        onSubmit={handleSubmit}
      >
        <div className="w-full flex flex-col gap-[40px] ">
          <div>
            <h1 className="text-[#000] text-[14px] font-[500]">
              Select Question
            </h1>

            <Autocomplete
              disablePortal
              disableClearable
              id="combo-box-demo"
              className="questionAutoComplete"
              value={question?.questionText}
              options={allQuestions
                ?.filter((data) => data?.questionType == "Text - Open-end")
                ?.filter((d) => {
                  return !edit?.isEdit
                    ? !data?.qualification?.find((q) => q?.question === d?._id)
                    : d;
                })
                ?.map((data, ind) => {
                  return {
                    label: data?.questionText,
                    value: data?._id,
                  };
                })}
              renderInput={(params) => <TextField {...params} />}
              onChange={(event, newValue) => {
                setQuestion(
                  allQuestions?.find((data) => data?._id == newValue.value)
                );
                setValidOptions("");
              }}
            />
          </div>
          <div className="w-full ">
            <h1 className="text-[#000] text-[14px] font-[500]">
              Allow Responses
            </h1>
            <div className="w-full flex gap-[19px]">
              <input
                type="text"
                placeholder="Add Text"
                value={validOptions}
                onChange={(e) => setValidOptions(e.target.value)}
                className="w-full border border-[#E0E0E0]  rounded-[6.5px] pl-3 text-[#666] font-[400] text-[12px] h-[40px] mt-3 bg-[#F9F9FF]"
              />
            </div>
          </div>
        </div>
        {loading ? (
          <div className="flex justify-center w-full">
            <CircularProgress className="text-center" size={23} />
          </div>
        ) : (
          <div className="w-full flex items-center justify-center">
            <button
              type="submit"
              className="text-[#fff] inter text-[16px] font-[400] bg-[#0226BE] rounded-[4px] py-[8px] px-[16px]"
            >
              Set Qualification
            </button>
          </div>
        )}
      </form>
    );
  };
  const renderAddQualification = () => {
    switch (tab) {
      case 0:
        return (
          <MultiPunch
            allQuestions={allQuestions}
            edit={edit}
            getTableQualification={getTableQualification}
          />
        );
      case 1:
        return (
          <SinglePunch
            allQuestions={allQuestions}
            edit={edit}
            getTableQualification={getTableQualification}
          />
        );
      case 2:
        return (
          <Numeric
            allQuestions={allQuestions}
            edit={edit}
            getTableQualification={getTableQualification}
          />
        );
      case 3:
        return (
          <Text
            allQuestions={allQuestions}
            edit={edit}
            getTableQualification={getTableQualification}
          />
        );
      default:
        return <h1>No data</h1>;
    }
  };
  const handleCloseModal = (e) => setDeleteM((prev) => ({ open: false }));
  const deleteQualification = async (e) => {
    e.preventDefault();
    axios
      .put(
        `${BASE_URL}/survey/delete-qualification?sid=${id}&qid=${deleteM?.data?._id}`
      )
      .then((res) => {
        getSingleSurvey();
        setDeleteM({ open: false });
        setSnackbar({
          msg: "Supplier Deleted Successfully !",
          open: true,
          severity: "success",
        });
      })
      .catch((er) => {
        getSingleSurvey();
        setDeleteM({ open: false });
        setSnackbar({
          msg: "Something went wrong !",
          open: false,
          severity: "error",
        });
      });
  };
  // console.log(qualificationTable);
  // console.log(filters);
  // console.log(edit);
  // console.log(previewData);
  return (
    <div className="w-full flex flex-col gap-[28px]  qualifi pb-5">
      <div className="w-full flex items-center justify-between">
        <select
          className="border border-[#E0E0E0] rounded-[8px] py-[8px] px-[16px] text-[#666666] text-[14px] h-[40px]"
          onChange={(e) =>
            setFilters((prev) => ({ ...prev, status: e.target.value }))
          }
        >
          <option value="">All Status</option>
          {["Active", "Inactive"]?.map((d) => (
            <option value={d}>{d}</option>
          ))}
        </select>
        <div className="w-[50%]  flex items-center justify-end gap-[12px]">
          <div className=" w-2/4 flex items-center relative">
            <AiOutlineSearch
              color="#666666"
              size={16}
              className="absolute left-[10px]"
            />
            <input
              type="text"
              className="w-full border border-[#E0E0E0]  rounded-[8px] pl-9 pr-2 text-[#12112766] font-[400] text-[12px] h-[40px]"
              placeholder="search by question ID or qualification name"
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, search: e.target.value }))
              }
            />
          </div>
          <button
            className="w-[30%] text-[#fff] rounded-[4px]  bg-[#0226BE] text-[14px] font-[400]   h-[40px]"
            onClick={(e) => handleOpen("qualification")}
          >
            Add Qualification
          </button>
        </div>
      </div>
      <div className="w-full tableBorder flex flex-col">
        <div className="w-full overflow-x-auto containerScroll">
          <table
            className="whitespace-nowrap text-left w-full"
            id="table-to-xls-DATA"
          >
            <thead className=" h-[45px] ">
              <tr className="py-3">
                <th className="text-[#464545] text-[12px] font-[600] px-5">
                  Question ID
                </th>
                <th className="text-[#666] text-[12px] font-[600] px-3">
                  Qualifications
                </th>
                <th className="text-[#666] text-[12px] font-[600] px-3 ">
                  Conditions
                </th>
                <th className="text-[#666] text-[12px] font-[600] px-3">
                  Status Action
                </th>
                <th className="text-[#666] text-[12px] font-[600] px-3">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {qualificationTable
                ?.filter((f) =>
                  !filters?.status
                    ? f
                    : filters?.status === "Active"
                    ? f.status === true
                    : f?.status === false
                )
                ?.filter((d) =>
                  !filters?.search
                    ? d
                    : d?.question?.questionName
                        ?.toLowerCase()
                        ?.includes(filters?.search?.toLowerCase()) ||
                      String(d?.question?.questionId)?.includes(filters?.search)
                )
                ?.map((data, ind) => (
                  <tr
                    className=" tableBorder transition-all cursor-pointer hover:bg-[#f6f9ff]"
                    key={ind}
                  >
                    <td className="px-5 py-5 text-[#667085] font-[400] text-[14px] ">
                      {data?.question?.questionId ?? data?.question?._id ?? "-"}
                    </td>
                    <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                      {data?.question?.questionName || "-"}
                    </td>
                    <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                      <div className="flex items-center gap-[16px] max-w-[85%] flex-wrap max-h-[130px] overflow-y-auto containerScroll">
                        {data?.validOptions?.filter((d) => d)?.length
                          ? data?.validOptions?.map((mdata) => (
                              <h1 className="py-[4px] px-[8px] border text-[10px] border-[#0226BE] text-[#0226BE] rounded-[4px]">
                                {data?.question?.questionType ===
                                "Numeric - Open-end"
                                  ? `${mdata?.from}-${mdata?.to}`
                                  : mdata}
                              </h1>
                            ))
                          : "No Conditions"}
                      </div>
                    </td>
                    <td className="px-3 py-5  font-[400] text-[14px] ">
                      <div className="flex items-center gap-[8px]">
                        <p
                          className={`${
                            data?.status ? "bg-[#D7FFC9]" : "bg-[#F3F3F3]"
                          }  rounded-[15px] flex items-center justify-center 
                        ${data?.status ? "text-[#37AB00]" : "text-[#4F4E4E]"}
                         text-[10px] w-[60px] h-[23px]`}
                        >
                          {data?.status ? "Active" : "Inactive"}
                        </p>
                        <label class="relative inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            value=""
                            checked={data?.status}
                            onChange={(e) => {
                              if (!e.target.checked) {
                                if (
                                  qualificationTable?.filter((d) => d?.status)
                                    ?.length > 1
                                ) {
                                  toggleQualificationStatus(data?._id);
                                } else {
                                  setSnackbar({
                                    open: true,
                                    msg: "One qualification must be active !",
                                    severity: "error",
                                  });
                                }
                              } else {
                                toggleQualificationStatus(data?._id);
                              }
                            }}
                            className="sr-only peer"
                          />
                          <div className="w-9 h-5 bg-gray-200 peer peer-focus:outline-none rounded-full  dark:bg-gray-400 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[4px] after:start-[8px]  after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-gray-600 peer-checked:bg-[#0226BE]"></div>
                        </label>
                      </div>
                    </td>
                    <td className="px-3 py-5  font-[400] text-[14px] ">
                      <div className="flex items-center gap-[11px] text-[20px] text-[#333333]">
                        <MdOutlineModeEditOutline
                          onClick={(e) => {
                            handleOpen("qualification");
                            setEdit((prev) => ({
                              ...prev,
                              isEdit: true,
                              data: data,
                            }));
                            switch (data?.question?.questionType) {
                              case "Multi Punch":
                                return setTab(0);
                              case "Single Punch":
                                return setTab(1);
                              case "Numeric - Open-end":
                                return setTab(2);
                              case "Text - Open-end":
                                return setTab(3);
                            }
                          }}
                        />
                        <MdDeleteOutline
                          onClick={(e) => {
                            setDeleteM((prev) => ({
                              ...prev,
                              open: true,
                              data: data,
                            }));
                          }}
                        />
                        <AiOutlineEye
                          onClick={(e) => {
                            handleOpen("preview");
                            setPreviewData(data);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <DeleteModal
        open={deleteM?.open}
        handleClose={handleCloseModal}
        text={deleteM?.data?.question?.questionName}
        handleClick={deleteQualification}
      />
      {/* Add Qualification Modal*/}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modal.qualification}
        onClose={(e) => handleClose("qualification")}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modal.qualification}>
          <Box sx={style}>
            <div className="w-full flex items-center flex-col py-1 pb-3">
              <div className="w-full  py-6 relative ">
                <h1 className="text-[#333333] text-[20px] font-[500]  pl-5">
                  {edit?.isEdit ? "Edit" : "Add"} Qualification Question
                </h1>
                <p
                  className="  py-1 px-1 rounded-[50%] w-fit absolute top-3 cursor-pointer right-4"
                  onClick={handleClose}
                >
                  <RxCross2 size={22} />
                </p>
              </div>
              <div className="w-full  flex items-center  pl-5 py-3 gap-[20px]">
                {[
                  "Multi Choice",
                  "Single Choice",
                  "Numeric-Open End",
                  "Text Entry",
                ]?.map((data, ind) => (
                  <h1
                    className={`text-[#000] text-[12px] font-[400] cursor-pointer transition-all ease-linear ${
                      tab == ind &&
                      `text-[#0226BE] font-[500] underline decoration-2 decoration-[#0226BE] underline-offset-[10px]`
                    } ${
                      edit?.isEdit && tab != ind
                        ? "text-gray-400 cursor-not-allowed"
                        : "text-[#000] cursor-pointer "
                    }`}
                    onClick={(e) => {
                      if (!edit?.isEdit) {
                        setTab(ind);
                      }
                    }}
                  >
                    {data}
                  </h1>
                ))}
              </div>
              {renderAddQualification()}
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* preview modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modal?.preview}
        onClose={(e) => handleClose("preview")}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modal?.preview}>
          <Box sx={style}>
            <div className="w-full flex items-center flex-col">
              <div className="w-full py-6 border-b border-[#E0E0E0]  text-center relative">
                <h1 className="text-[#333333] text-[20px] font-[600]">
                  Question Preview
                </h1>
                <p
                  className=" bg-[#F1F1F1] py-1 px-1 rounded-[50%] w-fit absolute top-3 cursor-pointer right-4"
                  onClick={(e) => handleClose("preview")}
                >
                  <RxCross2 size={16} />
                </p>
              </div>

              <div className="w-full  p-5 flex flex-col gap-[20px]">
                <h1 className="text-[#000] text-[20px] font-[500] inter capitalize">
                  {previewData?.question?.questionText}
                </h1>
                {/* {(previewData?.question?.questionType == "Multi Punch" ||
                  previewData?.question?.questionType == "Single Punch") &&
                Object.keys(previewData?.condition || {})?.length ? (
                  <h1 className={`pl-2 bg-gray-200 py-2 text-[#666]`}>
                    <Required />{" "}
                    {previewData?.condition?.min === previewData?.condition?.max
                      ? `You need to select exactly ${previewData?.condition?.min} options.`
                      : previewData?.condition?.min &&
                        previewData?.condition?.max
                      ? `You can only select minimum ${previewData?.condition?.min} and maximum ${previewData?.condition?.max}`
                      : previewData?.condition?.min &&
                        !previewData?.condition?.max
                      ? `You need to atleast select ${previewData?.condition?.min}`
                      : !previewData?.condition?.min &&
                        previewData?.condition?.max
                      ? `You need to atleast select only maximum of ${previewData?.condition?.max}`
                      : ""}
                  </h1>
                ) : (
                  ""
                )} */}
                {previewData?.question?.questionType == "Multi Punch" ||
                previewData?.question?.questionType == "Single Punch" ? (
                  <div className="w-full  max-h-[250px]  overflow-y-auto containerScroll flex flex-col gap-[8px] border border-[#e5e7eb] px-4 py-3">
                    {previewData?.displayOptions?.map((data, ind) => (
                      <div className="w-full pr-2 flex items-center gap-[12px] mb-4 ">
                        {previewData?.question?.questionType ==
                        "Multi Punch" ? (
                          <input
                            type="checkbox"
                            name={data}
                            id={data}
                            // checked={previewData?.validOptions?.includes(data)}
                            className="w-[18px] h-[18px]"
                          />
                        ) : (
                          <input
                            type="radio"
                            name="singlePunchOption"
                            // checked={previewData?.validOptions?.includes(data)}
                            id={data}
                            className="w-[18px] h-[18px]"
                          />
                        )}

                        <label
                          htmlFor={data}
                          className={`text-[16px] font-[400] text-[#333] w-[90%]  capitalize ${
                            previewData?.validOptions?.includes(data)
                              ? "cursor-pointer"
                              : "cursor-not-allowed"
                          }`}
                        >
                          {data}
                        </label>
                      </div>
                    ))}
                  </div>
                ) : (
                  <input
                    type={`${
                      previewData?.question?.questionType ==
                      "Numeric - Open-end"
                        ? "number"
                        : "text"
                    }`}
                    step="any"
                    placeholder="Add Text"
                    className="w-[60%] border border-[#E0E0E0]  rounded-[6.5px] pl-3 text-[#666] font-[400] text-[12px] h-[40px] mt-3 bg-[#F9F9FF]"
                  />
                )}
                <div className="">
                  <h1 className="text-[#000] text-[18px] font-[500] inter">
                    Answer should be
                  </h1>
                  <div className="py-3 flex items-center gap-[15px] w-full flex-wrap max-h-[150px] overflow-y-auto containerScroll">
                    {previewData?.question?.questionType !==
                    "Numeric - Open-end"
                      ? previewData?.validOptions?.map((d) => (
                          <h1 className="border border-[#0226BE] text-[#0226BE] bg-[#0226BE26] py-[4px] px-[8px] text-[12px] rounded-[4px] capitalize w-fit">
                            {d}
                          </h1>
                        ))
                      : previewData?.validOptions?.map((d) => (
                          <h1 className="border border-[#0226BE] text-[#0226BE] bg-[#0226BE26] py-[4px] px-[8px] text-[12px] rounded-[4px] capitalize w-fit">
                            {d?.from}-{d?.to}
                          </h1>
                        ))}
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* ADD LOGIC MODAL FIRST */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={addLogicModal.firstModal}
        onClose={(e) => {
          setAddLogicModal({
            firstModal: false,
            secondModal: false,
          });
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={addLogicModal.firstModal}>
          <Box sx={firstModalStyle}>
            <div className="w-full flex items-center flex-col py-1 pb-3">
              <div className="w-full  py-6 relative border-b ">
                <h1 className="text-[#333333] text-center text-[20px] font-[500]  pl-5">
                  Add Logic
                </h1>
                <p
                  className="  py-1 px-1 rounded-[50%] w-fit absolute top-3 cursor-pointer right-4"
                  onClick={() => {
                    setAddLogicModal({
                      firstModal: false,
                      secondModal: false,
                    });
                  }}
                >
                  <RxCross2 size={22} />
                </p>
              </div>
              <div className=" flex flex-col m-[16px]  rounded-[8px] pl-5 py-3  border w-[95%]">
                <div className="flex flex-col w-[94%] gap-[20px]">
                  <div className="flex text-[14px] flex-col gap-2">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      className=" border rounded-[4px] px-[16px] py-[6px] text-[#12112766]"
                      placeholder="Mayank Patel"
                    />
                  </div>

                  <div className="flex text-[14px] flex-col gap-2">
                    <label htmlFor="">Add another conditions</label>
                    <input
                      type="text"
                      className=" border rounded-[4px] px-[16px] py-[6px] text-[#12112766]"
                      placeholder="Mayank Patel"
                    />
                  </div>

                  <div className="flex text-[14px] justify-between">
                    <div className="flex flex-col gap-2">
                      <label htmlFor="">From</label>
                      <input
                        type="text"
                        className="w-[181px] border rounded-[4px] px-[16px] py-[6px] text-[#12112766]"
                        placeholder="Mayank Patel"
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <label htmlFor="">To</label>
                      <input
                        type="text"
                        className="w-[181px] border rounded-[4px] px-[16px] py-[6px] text-[#12112766]"
                        placeholder="Mayank Patel"
                      />
                    </div>
                  </div>

                  <div className="h-[40px] text-[14px] flex gap-5 border items-center rounded-[8px]  px-[16px] bg-[#FAFAFA]">
                    <input type="radio" name="" id="checkR" />
                    <span>IS</span>
                  </div>

                  <div className="h-[40px] text-[14px] flex gap-5 border items-center rounded-[8px]  px-[16px] bg-[#FAFAFA]">
                    <input type="radio" name="" id="checkR" />
                    <span>IS NOT</span>
                  </div>

                  <div className="flex items-center justify-between w-[60%]">
                    <span className="text-[14px] text-[#333]">
                      Add Another Conditions
                    </span>
                    <span>
                      <IoMdAddCircleOutline />
                    </span>
                  </div>

                  <button
                    className="bg-[#0226BE] text-[white] justify-center items-center flex h-[40px] rounded-[4px]"
                    onClick={() => {
                      setAddLogicModal({
                        firstModal: false,
                        secondModal: true,
                      });
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* ADD LOGIC MODAL SECOND */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={addLogicModal.secondModal}
        onClose={(e) => {
          setAddLogicModal({
            firstModal: false,
            secondModal: false,
          });
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={addLogicModal.secondModal}>
          <Box sx={firstModalStyle}>
            <div className="w-full flex items-center flex-col py-1 pb-3">
              <div className="w-full  py-6 relative border-b ">
                <h1 className="text-[#333333] text-center text-[20px] font-[500]  pl-5">
                  Add Logic
                </h1>
                <p
                  className="  py-1 px-1 rounded-[50%] w-fit absolute top-3 cursor-pointer right-4"
                  onClick={() => {
                    setAddLogicModal({
                      firstModal: false,
                      secondModal: false,
                    });
                  }}
                >
                  <RxCross2 size={22} />
                </p>
              </div>
              <div className=" flex flex-col m-[16px]  rounded-[8px] pl-5 py-3  border w-[95%]">
                <div className="flex flex-col w-[95%] gap-[20px]">
                  <div className="flex text-[14px] flex-col gap-2">
                    <label htmlFor="name">Result</label>
                    <div className="h-[40px] text-[14px] flex gap-5 border items-center rounded-[8px]  px-[16px] bg-[#FAFAFA]">
                      <input type="radio" name="" id="checkR" />
                      <span>Jump to</span>
                    </div>
                  </div>

                  <select
                    name=""
                    id=""
                    className=" h-[40px] border rounded-[4px] px-[16px] py-[6px] text-[#12112766]"
                  >
                    <option value="">Age</option>
                  </select>

                  <div className="flex text-[14px] flex-col gap-2">
                    <div className="h-[40px] text-[14px] flex gap-5 border items-center rounded-[8px]  px-[16px] bg-[#FAFAFA]">
                      <input type="radio" name="" id="checkR" />
                      <span>Terminate</span>
                    </div>
                  </div>

                  <div className="flex text-[14px] flex-col gap-2">
                    <div className="h-[40px] text-[14px] flex gap-5 border items-center rounded-[8px]  px-[16px] bg-[#FAFAFA]">
                      <input type="radio" name="" id="checkR" />
                      <span>Jump to client survey</span>
                    </div>
                  </div>

                  <button className="bg-[#0226BE] text-[white] justify-center items-center flex h-[40px] rounded-[4px]">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default Qualification;
