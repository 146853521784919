import axios from "axios";

// ! comment this in deployment
// export const BASE_URL = "http://localhost:9701/api/diy";

// ! uncomment this in deployment
export const BASE_URL = "https://apiservice.miratsquanto.com/api/diy";

// do comment this in deployment
export const PUBLISHER_URL =
  "https://api.publisher.miratsquanto.com/api/v1/publisher";
// export const PUBLISHER_URL = "http://localhost:1234/api/v1/publisher";
// ! comment in below code in production
export const tmpToken = getCookie1("diy_token");

export function getCookie1(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}
export function addDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result?.toLocaleDateString("en-in", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
}
export const getCurrentSurvey = async (surveyId) => {
  let data = await axios.get(`${BASE_URL}/survey/one?id=${surveyId}`);
  // console.log("getCurrentSurvey=>", data.data);
  return data.data;
};

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function calculatePercentageChange(yesterday, today) {
  if (yesterday == 0 && today == 0) {
    return 0;
  } else if (yesterday == 0) {
    return 100;
  }
  let result = ((today - yesterday) / yesterday) * 100;
  return result.toFixed(2);
}

export function generateRandomName() {
  const chars =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let result = "survey-group-";
  for (let i = 0; i < 10; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}
export const hideNav = [
  "sign-up",
  "login",
  "DIY",
  "error",
  "verify-change",
  "reset-password",
  "7e08091a73b14e034889265e41ba796f91c766ad",
  "21df0c3543ff3bd23cecdaf921ff4a704a113d06dc1ec21045a186ad0f2f9a42",
  "payment",
  "terms-and-condition",
  "privacy-policy",
  "refund-policy",
  "onboarding",
  "/surveys/",
  "/profile/",
  "/e-invoice/",
  "reconciliation-verification",
  "success",
  "transaction-failure",
];
export function timeDifference(ms) {
  const currentTime = new Date().getTime();
  const difference = currentTime - ms;

  // Time units in milliseconds
  const seconds = 1000;
  const minutes = 60 * seconds;
  const hours = 60 * minutes;
  const days = 24 * hours;
  const weeks = 7 * days;
  const months = 30 * days; // Approximate month (30 days)
  const years = 365 * days; // Approximate year (365 days)

  if (difference < minutes) {
    // If difference is less than a minute
    return `${Math.floor(difference / seconds)} s`;
  } else if (difference < hours) {
    // If difference is less than an hour
    return `${Math.floor(difference / minutes)} min`;
  } else if (difference < days) {
    // If difference is less than a day
    return `${Math.floor(difference / hours)} hrs`;
  } else if (difference < weeks) {
    // If difference is less than a week
    return `${Math.floor(difference / days)} days`;
  } else if (difference < months) {
    // If difference is less than a month
    return `${Math.floor(difference / weeks)} weeks`;
  } else if (difference < years) {
    // If difference is less than a year
    return `${Math.floor(difference / months)} months`;
  }
  // else if (isNaN(difference / years)) {
  //   return false;
  // }
  else {
    // If difference is more than or equal to a year
    return `${Math.floor(difference / years)} years`;
  }
}
