import React, { useEffect, useState } from "react";
import { ClientJS } from "clientjs";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL, getCookie, getCurrentSurvey, setCookie } from "../Utlils";
const DIYStart = () => {
  const { sId, supId, orgId } = useParams();
  const [preSession, setPreSession] = useState({});
  const client = new ClientJS();
  const fingerPrint = client.getFingerprint();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const srcID = searchParams.get("SRCID");
  const rID = searchParams.get("RID");
  let gamma = searchParams.get("gamma");
  const p = searchParams.get("p");
  const org = searchParams.get("org");
  const source_id = searchParams.get("source_id");
  const utm_id = searchParams.get("utm_id");
  // console.log(gamma, sId, srcID);
  async function getGeoData() {
    // let url = `https://api.ipgeolocation.io/ipgeo?apiKey=5f5886809c854f3fae3312db6a01c6e0`;
    let url = `https://echoip.miratsapiservices.com/ip-data`;
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error("Error occurred:", error);
    }
  }
  async function getIpAddress() {
    // console.log("inside getIpAddress");
    try {
      const response = await axios
        .get("https://echoip.miratsapiservices.com/get-ip")
        .then((dat) => dat.data.ipv4);

      return response;
    } catch (error) {
      console.error("Error occurred:", error);
    }
  }
  localStorage.setItem("srcID", supId);
  localStorage.setItem("rID", rID);
  localStorage.setItem("gamma", gamma);
  const getPreSession = async (e) => {
    let data = await axios.get(
      `${BASE_URL}/session/check-pre-session?sid=${sId}&src=${supId}&test=${
        gamma ? true : false
      }`
    );
    // console.log(data?.data);
    setPreSession(data.data);
  };
  const checkPreSession = async () => {
    let flag = false;
    if (gamma !== "alpha") {
      for (let [key, value] of Object.entries(preSession)) {
        if (key === "survey" && value === false) {
          flag = true;
          let originalUrl = `${window.location.origin}/error/1`;
          window.location.href = originalUrl;
        } else if (key === "supplier" && value === false) {
          flag = true;
          let originalUrl = `${window.location.origin}/error/2`;
          window.location.href = originalUrl;
        } else if (key === "surveyLive" && value === false) {
          flag = true;
          let originalUrl = `${window.location.origin}/error/4`;
          window.location.href = originalUrl;
        }
      }
    }
    if (!flag) {
      // console.log("everything is correct");
      createSession();
    } else {
      // console.log("terminate the survey");
    }
  };
  async function checkSecurity(ip) {
    let data = await axios.get(
      `${BASE_URL}/check-security?sid=${sId}&src=${supId}&test=${
        gamma ? true : false
      }&rid=${rID}&fingerprint=${fingerPrint}&ip=${ip}`
    );
    return data.data;
  }
  async function createSession() {
    try {
      let geo_data;
      try {
        geo_data = await getGeoData();
        if (!geo_data) {
          throw new Error("Geo data not loaded");
        }
      } catch (geoError) {
        console.error("Error fetching geo data:", geoError);
        window.location.href = `${window.location.origin}/error/add-blocker-detected`;
        return;
      }
      const ip = geo_data?.ip;
      let result = await checkSecurity(ip);
      const currentSurvey = await getCurrentSurvey(sId);
      let cookieExists = getCookie(`survey-${sId}`);

      if (gamma !== "alpha") {
        // Check if surveyCountryCode matches any of the geo data fields
        const surveyCountryCode = currentSurvey?.setup?.countryCode;
        const geoCountryMatch =
          geo_data.country_code2 === surveyCountryCode ||
          geo_data.country_code3 === surveyCountryCode ||
          geo_data.country_name === currentSurvey?.setup?.country;

        if (!geoCountryMatch) {
          window.location.href = `${window.location.origin}/error/5`;
          return;
        }
      }
      const sessionResponse = await axios.post(`${BASE_URL}/session`, {
        fingerprint: fingerPrint,
        srcid: srcID,
        rid: rID,
        surveyCPI: currentSurvey?.setup?.surveyCpi,
        supplierAccountId: supId,
        survey: sId,
        sId,
        test: gamma ? true : false,
        organizationId: currentSurvey?.createdBy,
        geoData: geo_data,
        publisherProjectId: p,
        publisherOrganizationId: org,
        source_id,
        utm_id,
      });

      const sessionId = sessionResponse.data._id;
      const refId = sessionResponse.data.ref_id;

      const overQuota = await axios
        .get(`${BASE_URL}/session/check-overquota?sid=${sId}&src=${supId}`)
        .then((res) => res.data);

      if (gamma !== "alpha") {
        await handleNonAlphaScenarios({
          cookieExists,
          sessionId,
          refId,
          result,
          overQuota,
        });
      } else {
        await handleAlphaScenarios({
          cookieExists,
          sessionId,
          refId,
          overQuota,
        });
      }
    } catch (error) {
      console.error("Error in createSession:", error);
    }
  }

  async function handleNonAlphaScenarios({
    cookieExists,
    sessionId,
    refId,
    result,
    overQuota,
  }) {
    if (cookieExists) {
      await updateSessionAndRedirect(sessionId, -1, 36, refId, 20);
    } else if (Object.keys(result).length) {
      await updateSessionAndRedirect(
        sessionId,
        result.client_status,
        result.mirats_status,
        refId,
        20
      );
    } else if (!overQuota.isSupplierActive) {
      await updateSessionAndRedirect(sessionId, -1, 127, refId, 20);
    } else if (overQuota.surveyAllocationFull) {
      await updateSessionAndRedirect(sessionId, -1, 40, refId, 40);
    } else if (overQuota.supplierAllocationFull) {
      await updateSessionAndRedirect(sessionId, -1, 41, refId, 40);
    } else {
      saveSessionDataLocally(sessionId, refId);
      window.location.href = `${window.location.origin}/DIY/${sId}/${supId}/${orgId}/gdpr-consent`;
    }
  }

  async function handleAlphaScenarios({
    cookieExists,
    sessionId,
    refId,
    overQuota,
  }) {
    if (!overQuota.isSupplierActive) {
      await updateSessionAndRedirect(sessionId, -1, 127, refId, 20);
    } else {
      if (cookieExists) {
        alert("Cookie Detected !");
      }
      saveSessionDataLocally(sessionId, refId);
      window.location.href = `${window.location.origin}/DIY/${sId}/${supId}/${orgId}/gdpr-consent`;
    }
  }

  async function updateSessionAndRedirect(
    sessionId,
    clientStatus,
    miratsStatus,
    refId,
    code
  ) {
    try {
      await axios.put(`${BASE_URL}/session?id=${sessionId}`, {
        client_status: clientStatus,
        mirats_status: miratsStatus,
      });
      window.location.href = `${
        window.location.origin
      }/7e08091a73b14e034889265e41ba796f91c766ad/${encodeURIComponent(
        refId
      )}/${code}`;
    } catch (error) {
      console.error("Error updating session and redirecting:", error);
    }
  }

  function saveSessionDataLocally(sessionId, refId) {
    localStorage.setItem("ref_id", refId);
    setCookie(`ref_id`, refId, 15);
    localStorage.setItem("session_id", sessionId);
    setCookie(`session-${sId}`, sessionId, 15);
    setCookie(`survey-${sId}`, sId, 15);
  }

  useEffect(() => {
    getPreSession();
  }, []);
  useEffect(() => {
    if (Object.keys(preSession)?.length) {
      checkPreSession();
    }
  }, [Object.keys(preSession)?.length]);
  return (
    <div className="flex items-center justify-center  w-full min-h-screen">
      <div className=" flex flex-col space-y-5 justify-center items-center">
        <div
          className="w-[50px] h-[50px] bg-transparent border-[5px] border-blue-700 border-t-transparent App-logo rounded-full"
          style={{ backgroundColor: "transparent !important" }}
        ></div>
        <h1 className="text-[20px] text-[#666]">
          Fetching the survey for you....
        </h1>
      </div>
    </div>
  );
};

export default DIYStart;
