import "./App.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Survey from "./pages/survey/Survey";
import SurveyGroup from "./pages/surveyGroup/SurveyGroup";
import Qualification from "./pages/Qualification/Qualification";
import Invoice from "./pages/Invoice/Invoice";
import GlobalContext from "./Contexts/GlobalContext";
import Navbar from "./components/Navbar";
import EditSurveyGroup from "./pages/surveyGroup/EditSurveyGroup";
import DetailInvoice from "./pages/Invoice/DetailsInvoice";
import LogIn from "./pages/LoginRegister/LogIn";
import SignUp from "./pages/LoginRegister/SignUp";
import Profile from "./pages/Profile/Profile";
import SurveyDetails from "./pages/survey/surveyDetails/SurveyDetails";
import VerifyChangeEmail from "./components/VerifyChangeEmail";
import { getCookie1, tmpToken } from "./Utlils";
import ResetPassword from "./pages/LoginRegister/ResetPassword";
import EncryptStart from "./Take-survey/EncryptStart";
import DIYStart from "./Take-survey/DIYStart";
import GDPR from "./Take-survey/GDPR";
import SurveyQualification from "./Take-survey/SurveyQualification";
import EndPage from "./Take-survey/EndPage";
import EndPoint from "./Take-survey/EndPoint";
import { auth } from "./FirebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import WarningBar from "./components/WarningBar";
import Payment from "./pages/Payment/Payment";
import PaymentDetails from "./pages/Payment/PaymentDetails";
import Onboarding from "./pages/Onboarding";
import AdditionalWarningBar from "./components/AdditionalWarningBar";
import { lazy, Suspense } from "react";
import Loader from "./components/Loader";
import Feasibility from "./pages/Feasibility/Feasibility";
import SecretKeyWarningBar from "./components/SecretKeyWarningBar";
import Success from "./pages/payment-checkout/Success";
import Failure from "./pages/payment-checkout/Failure";

const EInvoice = lazy(() => import("./pages/EInvoice"));
const TermsAndConditions = lazy(() => import("./pages/TermsAndConditions"));
const AddBlockerDetected = lazy(() => import("./pages/AddBlockerDetected"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const RefundPolicy = lazy(() => import("./pages/RefundPolicy"));
const SurveyNotLive = lazy(() => import("./Take-survey/SurveyNotLive"));
const ReconciliationVerification = lazy(() =>
  import("./pages/ReconciliationVerification/ReconciliationVerification")
);
const LoaderComp = () => {
  return (
    <div className="h-screen flex items-center justify-center">
      <Loader />
    </div>
  );
};
function App() {
  const ProtectedRoute = ({ children }) => {
    const [user, loading] = useAuthState(auth);
    if (!user && !loading) {
      return <Navigate to="/login" />;
    } else {
      return children;
    }
  };

  return (
    <GlobalContext>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route
            path="/e-invoice/:id"
            element={
              <Suspense fallback={<LoaderComp />}>
                <EInvoice />
              </Suspense>
            }
          />
          <Route path="/success/checkout/:id" element={<Success />} />
          <Route path="/transaction-failure" element={<Failure />} />
          <Route
            path="/reconciliation-verification/:id"
            element={
              <Suspense fallback={<LoaderComp />}>
                <ReconciliationVerification />
              </Suspense>
            }
          />
          <Route
            path="/reconciliation-verification/:id/:status"
            element={
              <Suspense fallback={<LoaderComp />}>
                <ReconciliationVerification />
              </Suspense>
            }
          />
          <Route
            path="/terms-and-conditions"
            element={
              <Suspense fallback={<LoaderComp />}>
                <TermsAndConditions />
              </Suspense>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <Suspense fallback={<LoaderComp />}>
                <PrivacyPolicy />
              </Suspense>
            }
          />
          <Route
            path="/refund-policy"
            element={
              <Suspense fallback={<LoaderComp />}>
                <RefundPolicy />
              </Suspense>
            }
          />
          <Route
            path="/DIY/v2/:surveyId/:encSupplierId/:orgId/start"
            element={
              <Suspense fallback={<LoaderComp />}>
                <EncryptStart />
              </Suspense>
            }
          />
          <Route
            path="/DIY/:sId/:supId/:orgId/lightningStart"
            element={
              <Suspense fallback={<LoaderComp />}>
                {" "}
                <DIYStart />
              </Suspense>
            }
          />
          <Route
            path="/DIY/:sId/:supId/:orgId/gdpr-consent"
            element={
              <Suspense fallback={<LoaderComp />}>
                <GDPR />
              </Suspense>
            }
          />
          <Route
            path="/DIY/:sId/:supId/:orgId/questions"
            element={
              <Suspense fallback={<LoaderComp />}>
                <SurveyQualification />
              </Suspense>
            }
          />
          <Route
            path="/7e08091a73b14e034889265e41ba796f91c766ad/:id/:status"
            element={
              <Suspense fallback={<LoaderComp />}>
                <EndPage />
              </Suspense>
            }
          />
          <Route
            path="/21df0c3543ff3bd23cecdaf921ff4a704a113d06dc1ec21045a186ad0f2f9a42"
            element={
              <Suspense fallback={<LoaderComp />}>
                <EndPoint />
              </Suspense>
            }
          />
          <Route
            path="/error/:s"
            element={
              <Suspense fallback={<LoaderComp />}>
                <SurveyNotLive />
              </Suspense>
            }
          />
          <Route
            path="/error/add-blocker-detected"
            element={
              <Suspense fallback={<LoaderComp />}>
                <AddBlockerDetected />
              </Suspense>
            }
          />
          <Route path="/verify-change-email" element={<VerifyChangeEmail />} />
          <Route path="/sign-up" element={<SignUp />} />
          {/* <Route path="/upload-excel" element={<UploadExcel />} /> */}
          <Route path="/sign-up/:id" element={<SignUp />} />
          <Route path="/login" element={<LogIn />} />
          <Route path="/login/:type" element={<LogIn />} />
          <Route path="/reset-password" element={<ResetPassword />} />{" "}
          <Route
            path="/onboarding"
            element={
              <ProtectedRoute>
                <Onboarding />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile/:type"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/surveys/:id/:type"
            element={
              <ProtectedRoute>
                <SurveyDetails />{" "}
              </ProtectedRoute>
            }
          />
          <Route
            path="/surveys/:id"
            element={
              <ProtectedRoute>
                <SurveyDetails />{" "}
              </ProtectedRoute>
            }
          />
          <Route
            // path="/payment"
            element={
              <ProtectedRoute>
                <Payment />
              </ProtectedRoute>
            }
          />
          <Route
            path="/payment/account"
            element={
              <ProtectedRoute>
                <PaymentDetails />
              </ProtectedRoute>
            }
          />
        </Routes>
        <div className={`w-full flex items-center justify-center `}>
          <div className={`w-[92%] `}>
            {/* <ProtectedRoute> */}
            <WarningBar />
            <AdditionalWarningBar />
            <SecretKeyWarningBar />
            <Routes>
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/surveys"
                element={
                  <ProtectedRoute>
                    <Survey />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/survey-groups"
                element={
                  <ProtectedRoute>
                    <SurveyGroup />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/feasibility"
                element={
                  <ProtectedRoute>
                    <Feasibility />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/qualification-library"
                element={
                  <ProtectedRoute>
                    <Qualification />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/invoice-billing"
                element={
                  <ProtectedRoute>
                    <Invoice />
                  </ProtectedRoute>
                }
              />

              {/* edit */}
              <Route
                path="/survey-groups/:id"
                element={
                  <ProtectedRoute>
                    <EditSurveyGroup />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/invoice-billing/:id"
                element={
                  <ProtectedRoute>
                    <DetailInvoice />
                  </ProtectedRoute>
                }
              />
              {/* <Route path="*" element={<h1>Error</h1>} /> */}
            </Routes>
            {/* </ProtectedRoute> */}
          </div>
        </div>
      </BrowserRouter>
    </GlobalContext>
  );
}

export default App;
