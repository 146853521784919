import React, { createContext, useContext, useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import { BASE_URL, getCookie1, tmpToken } from "../Utlils";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../FirebaseConfig";
import GlobalSkeleton from "../components/GlobalSkeleton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
};
const GlobalContextProvider = createContext();
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const useGlobalContext = () => {
  return useContext(GlobalContextProvider);
};

const GlobalContext = ({ children }) => {
  const [user, setUser] = useState({});
  const [userLoading, setUserLoading] = useState(true);

  const [globalLoader, setGlobalLoader] = useState({});

  //GET USER DATA:
  const getUser = async () => {
    try {
      setUserLoading(true);
      onAuthStateChanged(auth, async (user) => {
        if (user?.emailVerified) {
          let userData = await axios.get(
            `${BASE_URL}/users/one?id=${user?.uid}`
          );
          // console.log(userData?.data);
          if (userData?.data) {
            localStorage.removeItem("onboard-user");
          }
          const idToken = await user.getIdToken(true);
          localStorage.setItem("somehti", idToken);
          setUser(userData?.data);
          setUserLoading(false);
        } else {
          setUser({});
          setUserLoading(false);
        }
      });
    } catch (error) {
      setUserLoading(false);
      console.log(error, error.message);
    }
  };
  const studyTypesData = [
    {
      label: "Ad Effectiveness Research",
      value: "Ad_Effectiveness_Research",
    },
    {
      label: "Adhoc",
      value: "adhoc",
    },
    {
      label: "community-build",
      value: "community_build",
    },
    {
      label: "Face to Face",
      value: "face_to_face",
    },
    {
      label: "IHUT",
      value: "IHUT",
    },
    {
      label: "Internal Use",
      value: "internal_use",
    },
    {
      label: "Incidence Check",
      value: "incidence_check",
    },
    {
      label: "Qualitative Screening",
      value: "qualitative_screening",
    },
    {
      label: "Recontact",
      value: "recontact",
    },
    {
      label: "Recruit - Panel",
      value: "recruit_panel",
    },
    {
      label: "Tracking - Weekly",
      value: "tracking_weekly",
    },
    {
      label: "Tracking - Monthly",
      value: "tracking_monthly",
    },
    {
      label: "Tracking - Quaterly",
      value: "tracking_quaterly",
    },
    {
      label: "Tracking - Yearly",
      value: "tracking_yearly",
    },
    {
      label: "Tracking - Biyearly",
      value: "tracking_biyearly",
    },
    {
      label: "Wave Study",
      value: "wave_study",
    },
  ];
  const allLanguages = [
    "Albanian",
    "Arabic",
    "Basque",
    "Belarusian",
    "Bengali",
    "Bosnian",
    "Bulgarian",
    "Catalan",
    "Croatian",
    "Czech",
    "Danish",
    "Dutch",
    "English",
    "Estonian",
    "Finnish",
    "French",
    "German",
    "Greek",
    "Hindi",
    "Hungarian",
    "Icelandic",
    "Irish",
    "Italian",
    "Japanese",
    "Latvian",
    "Lithuanian",
    "Maltese",
    "Mandarin Chinese",
    "Norwegian",
    "Portuguese",
    "Russian",
    "Spanish",
  ];

  const industryData = [
    {
      label: "Agriculture",
      value: "agriculture",
    },
    {
      label: "Automotive & Mobility",
      value: "automotive_and_mobility",
    },
    {
      label: "Consumer Package Goods",
      value: "consumer_package_goods",
    },
    {
      label: "Construction",
      value: "construction",
    },
    {
      label: "Energy & Utilities",
      value: "energy_and_utilities",
    },
    {
      label: "Education",
      value: "education",
    },
    {
      label: "Fashion, Beauty & Luxury",
      value: "fashion_beauty_and_luxury",
    },
    {
      label: "Finance",
      value: "finance",
    },
    {
      label: "Government & Public Sector",
      value: "government_and_public_sector",
    },
    {
      label: "Health",
      value: "health",
    },
    {
      label: "Hospitality, Leisure & Travel",
      value: "hospitality_leisure_and_travel",
    },
    {
      label: "Media",
      value: "media",
    },
    {
      label: "Retail",
      value: "retail",
    },
    {
      label: "Sport & Leisure",
      value: "sport_and_leisure",
    },
    {
      label: "Technology & Telecoms",
      value: "technology_and_telecoms",
    },
    {
      label: "Others",
      value: "others",
    },
  ];
  const statusOptions = [
    {
      label: "Awarded",
      value: "awarded",
    },
    {
      label: "Archived",
      value: "archived",
    },
    {
      label: "Completed",
      value: "completed",
    },
    {
      label: "Live",
      value: "live",
    },
    {
      label: "Paused",
      value: "paused",
    },
    {
      label: "Billed",
      value: "billed",
    },
  ];
  const taxIdTypes = [
    "Australian Business Number (AU ABN)",
    "Australian Taxation Office Reference Number",
    "Brazil CNPJ number",
    "Brazil CPF number",
    "Bulgaria Unified Identification Code",
    "Canada BN",
    "Canada GST/HST number",
    "Canadian PST number (British Columbia)",
    "Canadian PST number (Manitoba)",
    "Canadian PST number (Saskatchewan)",
    "Canadian QST number (Québec)",
    "Chilean TIN",
    "Egyptian Tax Identification Number",
    "EU VAT number",
    "European One Stop Shop VAT number for non-Union scheme",
    "Georgian VAT",
    "Hong Kong BR number",
    "Hungary tax number (adószám)",
    "Icelandic VAT",
    "Indonesian NPWP number",
    "Indian GST number",
    "Israel VAT",
    "Japanese Corporate Number (Hōjin Bangō)",
    "Japanese Registered Foreign Businesses' Registration Number (Tōroku Kokugai Jigyōsha no Tōroku Bangō)",
    "Japanese Tax Registration Number (Tōroku Bangō)",
    "Kenya Revenue Authority Personal Identification Number",
    "Liechtensteinian UID number",
    "Malaysian FRP number",
    "Malaysian ITN",
    "Malaysian SST number",
    "Mexican RFC number",
    "New Zealand GST number",
    "Norwegian VAT number",
    "Philippines Tax Identification Number",
    "Russian INN",
    "Russian KPP",
    "Saudi Arabia VAT",
    "Singaporean GST",
    "Singaporean UEN",
    "Slovenia tax number (davčna številka)",
    "South African VAT number",
    "South Korean BRN",
    "Spanish NIF number",
    "Switzerland VAT number",
    "Taiwanese VAT",
    "Thai VAT",
    "Turkish Tax Identification Number",
    "Ukrainian VAT",
    "United Arab Emirates TRN",
    "United Kingdom VAT number",
    "United States EIN",
    "Andorran NRT number",
    "Argentinian tax ID number",
    "Bolivian tax ID",
    "Chinese tax ID",
    "Colombian NIT number",
    "Costa Rican tax ID",
    "Dominican RCN number",
    "Ecuadorian RUC number",
    "Peruvian RUC number",
    "Romanian tax ID number",
    "El Salvadorian NIT number",
    "Serbian PIB number",
    "Uruguayan RUC number",
    "Venezuelan RIF number",
    "Vietnamese tax ID number",
  ];
  const currencies = [
    {
      label: "US Dollar",
      value: "USD",
      symbol: "$",
    },
    {
      label: "Euro",
      value: "EUR",
      symbol: "€",
    },
    {
      label: "Japanese Yen",
      value: "JPY",
      symbol: "¥",
    },
    {
      label: "British Pound",
      value: "GBP",
      symbol: "£",
    },
    {
      label: "Australian Dollar",
      value: "AUD",
      symbol: "$",
    },
    {
      label: "Canadian Dollar",
      value: "CAD",
      symbol: "$",
    },
    {
      label: "Swiss Franc",
      value: "CHF",
      symbol: "CHF",
    },
    {
      label: "Chinese Yuan Renminbi",
      value: "CNY",
      symbol: "¥",
    },
    {
      label: "Swedish Krona",
      value: "SEK",
      symbol: "kr",
    },
    {
      label: "New Zealand Dollar",
      value: "NZD",
      symbol: "$",
    },
    {
      label: "Mexican Peso",
      value: "MXN",
      symbol: "$",
    },
    {
      label: "Singapore Dollar",
      value: "SGD",
      symbol: "$",
    },
    {
      label: "Hong Kong Dollar",
      value: "HKD",
      symbol: "$",
    },
    {
      label: "Norwegian Krone",
      value: "NOK",
      symbol: "kr",
    },
    {
      label: "South Korean Won",
      value: "KRW",
      symbol: "₩",
    },
    {
      label: "Turkish Lira",
      value: "TRY",
      symbol: "₺",
    },
    {
      label: "Russian Ruble",
      value: "RUB",
      symbol: "₽",
    },
    {
      label: "Indian Rupee",
      value: "INR",
      symbol: "₹",
    },
    {
      label: "Brazilian Real",
      value: "BRL",
      symbol: "R$",
    },
    {
      label: "South African Rand",
      value: "ZAR",
      symbol: "R",
    },
  ];

  const [allSurveyStats, setAllSurveyStats] = useState([]);
  const [surveyStatsConfig, setSurveyStatsConfig] = useState({
    loading: true,
    error: false,
    noData: false,
  });
  const [myQuestion, setMyQuestion] = useState([]);
  const [questionLibrary, setQuestionLibrary] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [allSurvey, setAllSurvey] = useState([]);
  const [allSurveyGroup, setAllSurveyGroup] = useState([]);
  const [allSuppliers, setAllSuppliers] = useState([]);
  const [allInvoices, setAllInvoices] = useState([]);
  const [organization, setOrganization] = useState({});
  const [countryData, setCountryData] = useState({
    isLoading: true,
    data: [],
    error: false,
  });
  const [allMonthsAndYear, setAllMonthsAndYear] = useState({
    months: [
      { name: "January", value: 0 },
      { name: "February", value: 1 },
      { name: "March", value: 2 },
      { name: "April", value: 3 },
      { name: "May", value: 4 },
      { name: "June", value: 5 },
      { name: "July", value: 6 },
      { name: "August", value: 7 },
      { name: "September", value: 8 },
      { name: "October", value: 9 },
      { name: "November", value: 10 },
      { name: "December", value: 11 },
    ],
    years: [],
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    msg: "",
    severity: "",
  });

  const getAllSurveyStats = async () => {
    try {
      setSurveyStatsConfig((prev) => ({ ...prev, loading: true }));

      let data = await axios(
        `${BASE_URL}/session/survey-sessions?organization=${user?.organizationId}`
      );
      data = data.data;
      setAllSurveyStats(data);
      if (data.length) {
        setSurveyStatsConfig((prev) => ({ ...prev, loading: false }));
      } else {
        setSurveyStatsConfig((prev) => ({
          ...prev,
          loading: false,
          noData: true,
        }));
      }
    } catch (error) {
      console.log("something went wrong while fetching the survey stats");
      setSurveyStatsConfig((prev) => ({
        ...prev,
        loading: false,
        error: true,
      }));
    }
  };
  // get all invoices
  const getAllInvoices = async () => {
    let data = await axios.get(
      `${BASE_URL}/invoice?orgId=${user?.organizationId}`
    );
    setAllInvoices(data.data);
  };
  const getOrganization = async (id = user?.organizationId) => {
    try {
      let data = await (await axios.get(`${BASE_URL}/company/${id}`)).data;
      setOrganization(data);
      if (
        !Object.keys(data || {})?.length &&
        window.location.pathname !== "/onboarding"
      ) {
        window.location.href = `${window.location.origin}/onboarding`;
      }
    } catch (error) {
      console.log("error in getting organization", error);
      setOrganization({});
    }
  };
  //GET TEAM MEMBERS:
  const getTeamMembers = async (e) => {
    setGlobalLoader((prev) => ({ teamMembers: true }));
    let data = await axios.get(
      `${BASE_URL}/users/team?orgId=${user?.organizationId}&id=${user?._id}`
    );
    setTeamMembers(data.data);
    setGlobalLoader((prev) => ({ teamMembers: false }));
  };

  //GET MY QUESTIONS:
  const getMyQuestion = async (e) => {
    // console.log("getMyQuestion function called");
    setGlobalLoader((prev) => ({ myQuestion: true }));
    let data = await axios.get(
      `${BASE_URL}/question?orgId=${user?.organizationId}`
    );
    setMyQuestion(data.data);
    setGlobalLoader((prev) => ({ myQuestion: false }));
  };

  //GET ALL SURVEYS:
  const getAllSurvey = async (e) => {
    setGlobalLoader((prev) => ({ allSurvey: true }));
    let data = await axios.get(
      `${BASE_URL}/survey?orgId=${user?.organizationId}`
    );
    setAllSurvey(data.data);
    setGlobalLoader((prev) => ({ allSurvey: false }));
  };

  //GET ALL SURVEY GROUPS:
  const getAllSurveyGroup = async (e) => {
    setGlobalLoader((prev) => ({ allSurveyGroup: true }));
    let data = await axios.get(
      `${BASE_URL}/survey-group?orgId=${user?.organizationId}`
    );
    setAllSurveyGroup(data.data);
    setGlobalLoader((prev) => ({ allSurveyGroup: false }));
  };

  //GET QUESTION LIBRARY:
  const getQuestionLibrary = async (e) => {
    setGlobalLoader((prev) => ({ questionLibrary: true }));
    let data = await axios.get(`${BASE_URL}/questionLibrary`);
    setQuestionLibrary(data.data);
    setGlobalLoader((prev) => ({ questionLibrary: false }));
  };

  //GET ALL SUPPLIERS:
  const getAllSuppliers = async (e) => {
    try {
      setGlobalLoader((prev) => ({ allSuppliers: true }));
      let data = await axios.get(`${BASE_URL}/supplier`);
      setAllSuppliers(data.data);
    } catch (error) {
    } finally {
      setGlobalLoader((prev) => ({ allSuppliers: false }));
    }
  };

  // months and year
  const getYears = async () => {
    let tempArr = [];
    for (let i = 5; i >= 0; i--) {
      tempArr.push(new Date().getFullYear() - i);
    }
    setAllMonthsAndYear((prev) => ({ ...prev, years: tempArr.reverse() }));
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  async function getCountries() {
    try {
      const countryData = await (
        await fetch("https://api.countrystatecity.in/v1/countries", {
          method: "GET",
          headers: {
            "X-CSCAPI-KEY": process.env.REACT_APP_COUNTRY_STATE_API_KEY,
          },
          redirect: "follow",
        })
      ).json();
      return countryData;
    } catch (error) {
      console.log(error);
    }
  }
  async function getAllCountryStates(country) {
    try {
      const statesData = await (
        await fetch(
          `https://api.countrystatecity.in/v1/countries/${country}/states`,
          {
            method: "GET",
            headers: {
              "X-CSCAPI-KEY": process.env.REACT_APP_COUNTRY_STATE_API_KEY,
            },
            redirect: "follow",
          }
        )
      ).json();
      return statesData;
    } catch (error) {
      console.log(error);
    }
  }
  async function getAllCities(country, state) {
    try {
      if (country && state) {
        const citiesData = await (
          await fetch(
            `https://api.countrystatecity.in/v1/countries/${country}/states/${state}/cities`,
            {
              method: "GET",
              headers: {
                "X-CSCAPI-KEY": process.env.REACT_APP_COUNTRY_STATE_API_KEY,
              },
              redirect: "follow",
            }
          )
        ).json();
        return citiesData;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function fetchCountries() {
    try {
      setCountryData({ isLoading: true });
      let data = await getCountries();
      setCountryData({ isLoading: false, data: data, error: false });
    } catch (error) {
      setCountryData({ isLoading: false, data: [], error: true });
    }
  }

  useEffect(() => {
    if (Object.keys(user)?.length !== 0) {
      getYears();
      getOrganization(user?.organizationId);
      getTeamMembers();
      getMyQuestion();
      getAllSurvey();
      getAllSurveyGroup();
      getAllSuppliers();
      getAllSurveyStats();
      getAllInvoices();
    } else {
      getUser();
      getQuestionLibrary();
      fetchCountries();
    }
  }, [Object?.keys(user || {})?.length]);
  // console.log(user);
  // console.log(organization);
  const value = {
    user,
    getUser,
    setUser,
    setSnackbar,
    myQuestion,
    getMyQuestion,
    allSurvey,
    allSurveyStats,
    teamMembers,
    allSurveyGroup,
    questionLibrary,
    allSuppliers,
    setAllSuppliers,
    getTeamMembers,
    getAllSurvey,
    getAllSurveyGroup,
    globalLoader,
    setGlobalLoader,
    getAllSurveyStats,
    allMonthsAndYear,
    allInvoices,
    studyTypesData,
    industryData,
    statusOptions,
    allLanguages,
    taxIdTypes,
    currencies,
    organization,
    setOrganization,
    getOrganization,
    getCountries,
    getAllCountryStates,
    getAllCities,
    countryData,
    surveyStatsConfig,
  };

  return (
    <GlobalContextProvider.Provider value={value}>
      {userLoading ? <GlobalSkeleton /> : children}

      <Snackbar
        open={snackbar?.open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={snackbar?.severity}
          sx={{ width: "100%" }}
        >
          {snackbar?.msg}
        </Alert>
      </Snackbar>
    </GlobalContextProvider.Provider>
  );
};

export default GlobalContext;
