import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { RxCross2 } from "react-icons/rx";
import { useGlobalContext } from "../../../../Contexts/GlobalContext";
import axios from "axios";
import { BASE_URL } from "../../../../Utlils";
import { CircularProgress } from "@mui/material";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { IoTrashBinOutline } from "react-icons/io5";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
};

const Quotas = ({ data, type, id, getSingleSurvey }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState({ selectQuestion: false, change: false });
  const { myQuestion, questionLibrary, setSnackbar } = useGlobalContext();
  const [inputData, setInputData] = useState({});
  const [filters, setFilters] = useState({});
  const [qualificationTable, setQualificationTable] = useState([]);
  const [quotaStats, setQuotaStats] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [editQuotaModal, setEditQuotaModal] = useState(false);
  const handleOpen = (name) => setOpen((prev) => ({ ...prev, [name]: true }));
  const handleClose = (name) => {
    setOpen((prev) => ({ ...prev, [name]: false }));
    setInputData({});
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (editQuotaModal) {
        const { question, _id, ...rest } = selectedRows?.[0];
        let singleQualification = qualificationTable?.find(
          (d) => d?._id == _id
        );
        await axios.put(`${BASE_URL}/survey/add-quota?sid=${id}&qid=${_id}`, {
          ...singleQualification?.quotas,
          ...rest,
        });
      } else {
        await axios.put(
          `${BASE_URL}/survey/add-quota?sid=${id}&qid=${inputData?.selectedQuestion}`,
          inputData?.quota
        );
      }
      getSingleSurvey();
      getTableQualification();
      setSnackbar({
        msg: "Quota Added Successfully !",
        open: true,
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        msg: "Something went wrong !",
        open: true,
        severity: "error",
      });
    } finally {
      setLoading(false);
      setEditQuotaModal(false);
      setInputData({});
      setSelectedRows([]);
      setOpen((prev) => ({ ...prev, change: false }));
    }
  };
  const deleteQuota = async (e) => {
    e.preventDefault();
    try {
      let allQuestion = data?.qualification;
      for (let question of allQuestion) {
        let { quotas } = question;
        for (let quota of selectedRows) {
          Object.keys(quota)?.forEach((d) => {
            if (quotas?.hasOwnProperty(d)) {
              delete quotas?.[d];
            }
          });
        }
      }
      await axios.put(`${BASE_URL}/survey?id=${id}`, {
        qualification: allQuestion,
      });
      await getSingleSurvey();
      setSnackbar({
        msg: "Quotas deleted successfully !",
        open: true,
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        msg: "Something went wrong !",
        open: true,
        severity: "error",
      });
    } finally {
      setSelectedRows([]);
    }
  };

  const getTableQualification = async (e) => {
    setQualificationTable([]);
    const questionMap = new Map(
      [...myQuestion, ...questionLibrary].map((d) => [d._id, d])
    );
    const updatedTable = data?.qualification
      ?.map((f) => {
        const question = questionMap.get(f.question);
        return { ...f, question };
      })
      .filter((item) => item.question);

    setQualificationTable(updatedTable);
  };
  const getQuotaStats = async (e) => {
    try {
      let data = await axios.get(`${BASE_URL}/session/quota-stats?sId=${id}`);
      setQuotaStats(data?.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  const selectQuotaRow = (e, data, OD) => {
    if (e.target.checked) {
      setSelectedRows((prev) => [...prev, data]);
    } else {
      setSelectedRows((prev) => prev.filter((d, i) => !d.hasOwnProperty(OD)));
    }
  };
  const filteredAddQuota = qualificationTable
    ?.filter(
      (d) =>
        d?.question?.questionType !== "Text - Open-end" &&
        d?.question?.questionType !== "Numeric - Open-end" &&
        (!d?.quotas || !Object.keys(d?.quotas)?.length)
    )
    ?.filter((fd) =>
      !inputData?.searchQuestion
        ? fd
        : fd?.question?.questionName
            ?.toLowerCase()
            ?.includes(inputData?.searchQuestion?.toLowerCase())
    );
  useEffect(() => {
    getQuotaStats();
    if (myQuestion && questionLibrary && data?.qualification) {
      getTableQualification();
    }
  }, [myQuestion, questionLibrary, data]);
  // console.log("id=>", id);
  // console.log("quotaStats=>", quotaStats);
  // console.log("inputData=>", inputData);
  // console.log("qualificationTable=>", qualificationTable);
  // console.log("selectedRows=>", selectedRows);
  return (
    <div className="w-full flex flex-col gap-[28px] ">
      <div className="w-full flex items-center justify-between">
        <select
          name=""
          id=""
          className="border border-[#E0E0E0] rounded-[8px] py-[8px] px-[16px] text-[#666666] text-[14px] h-[40px]"
          onChange={(e) =>
            setFilters((prev) => ({ ...prev, questionName: e.target.value }))
          }
        >
          <option value="" selected>
            Question Name
          </option>
          {qualificationTable?.map((d, key) => (
            <option value={d?.question?.questionName} key={key}>
              {" "}
              {d?.question?.questionName}
            </option>
          ))}
        </select>

        <button
          className="px-[16px] py-[8px] text-[#fff] rounded-[4px]  bg-[#0226BE] text-[14px] font-[400]   "
          onClick={(e) => handleOpen("selectQuestion")}
        >
          Add Quota
        </button>
      </div>

      <div>
        {selectedRows?.length ? (
          <div className="w-full tableBorder flex items-center justify-between px-6 py-5 ">
            <div className="flex items-center gap-4">
              <input
                type="checkbox"
                className="w-[15px] h-[15px] cursor-pointer ml-[-10px]"
                checked={selectedRows?.length}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedRows([]);
                  } else {
                    setSelectedRows([]);
                  }
                }}
              />
              {selectedRows?.length ? (
                <span className="text-[15px] text-[#0226BE]">
                  Selected: ({selectedRows?.length})
                </span>
              ) : (
                <></>
              )}
              {selectedRows?.length == 1 ? (
                <MdOutlineModeEditOutline
                  size={24}
                  className="cursor-pointer"
                  onClick={(e) => {
                    setEditQuotaModal(true);
                  }}
                />
              ) : (
                ""
              )}
              <IoTrashBinOutline
                size={24}
                onClick={deleteQuota}
                className="cursor-pointer"
              />
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="w-full tableBorder flex flex-col">
          <div className="w-full overflow-x-auto containerScroll">
            <table
              className="whitespace-nowrap text-left w-full"
              id="table-to-xls-DATA"
            >
              <thead className=" h-[45px] ">
                <tr className="py-3">
                  <th className="text-[#666666] text-[12px] font-[600] px-3">
                    {/* <input
                      type="checkbox"
                      className="w-[15px] h-[15px] cursor-pointer"
                    /> */}
                  </th>
                  <th className="text-[#666666] text-[12px] font-[600] px-3">
                    Question Name
                  </th>
                  <th className="text-[#666666] text-[12px] font-[600] px-3">
                    Quota Name
                  </th>
                  <th className="text-[#666666] text-[12px] font-[600] px-3">
                    Quota
                  </th>
                  <th className="text-[#666] text-[12px] font-[600] px-3">
                    Completes
                  </th>
                  <th className="text-[#666] text-[12px] font-[600] px-3">
                    Total Remaining
                  </th>
                  <th className="text-[#666] text-[12px] font-[600] px-3">
                    Conversion
                  </th>
                </tr>
              </thead>
              <tbody>
                {qualificationTable
                  ?.filter((f) =>
                    !filters?.questionName
                      ? f
                      : f?.question?.questionName === filters?.questionName
                  )
                  ?.map((mData, ind) => (
                    <>
                      {mData?.quotas ? (
                        <>
                          {Object?.keys(mData?.quotas)?.map((OD, key) => (
                            <tr
                              key={key}
                              className=" tableBorder transition-all cursor-pointer hover:bg-[#f6f9ff]"
                            >
                              <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                                <input
                                  type="checkbox"
                                  className="w-[15px] h-[15px] cursor-pointer"
                                  checked={selectedRows?.some(
                                    (d, i) => d?.[OD] == mData?.quotas?.[OD]
                                  )}
                                  onChange={(e) =>
                                    selectQuotaRow(
                                      e,
                                      {
                                        [OD]: mData?.quotas?.[OD],
                                        question: mData?.question?._id,
                                        _id: mData?._id,
                                      },
                                      OD
                                    )
                                  }
                                />
                              </td>
                              <td className="px-3 py-5  text-[#667085] font-[400] text-[14px]">
                                {mData?.question?.questionName}
                              </td>
                              <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] capitalize">
                                {OD}
                              </td>
                              <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                                {mData?.quotas?.[OD]}
                              </td>
                              <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                                {mData?.question?.questionType?.endsWith("end")
                                  ? Object.values(
                                      quotaStats?.[mData?._id] || {}
                                    )?.reduce((acc, curr) => (acc += curr), 0)
                                  : quotaStats?.[mData?._id]?.[OD] || 0}
                              </td>
                              <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                                {/* {mData?.quotas?.[OD]} */}
                                {mData?.question?.questionType?.endsWith("end")
                                  ? mData?.quotas?.[OD] -
                                      Object.values(
                                        quotaStats?.[mData?._id] || {}
                                      )?.reduce(
                                        (acc, curr) => (acc += curr),
                                        0
                                      ) >
                                    0
                                    ? mData?.quotas?.[OD] -
                                      Object.values(
                                        quotaStats?.[mData?._id] || {}
                                      )?.reduce((acc, curr) => (acc += curr), 0)
                                    : 0
                                  : (mData?.quotas?.[OD] -
                                      quotaStats?.[mData?._id]?.[OD] || 0) > 0
                                  ? mData?.quotas?.[OD] -
                                      quotaStats?.[mData?._id]?.[OD] || 0
                                  : 0}
                              </td>
                              <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                                {mData?.question?.questionType?.endsWith("end")
                                  ? (
                                      (Object.values(
                                        quotaStats?.[mData?._id] || {}
                                      )?.reduce(
                                        (acc, curr) => (acc += curr),
                                        0
                                      ) /
                                        mData?.quotas?.[OD]) *
                                      100
                                    )?.toFixed(2)
                                  : (
                                      ((quotaStats?.[mData?._id]?.[OD] || 0) /
                                        mData?.quotas?.[OD] ?? 0) * 100
                                    )?.toFixed(2)}
                                %
                              </td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* select Question */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open?.selectQuestion}
        onClose={(e) => handleClose("selectQuestion")}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open?.selectQuestion}>
          <Box sx={style}>
            <div className="w-full flex items-center flex-col">
              <div className="w-full py-6 border-b border-[#E0E0E0]  text-center relative">
                <h1 className="text-[#333333] text-[20px] font-[600]">
                  Change Qualification Quota
                </h1>
                <p
                  className=" bg-[#F1F1F1] py-1 px-1 rounded-[50%] w-fit absolute top-3 cursor-pointer right-4"
                  onClick={(e) => handleClose("selectQuestion")}
                >
                  <RxCross2 size={16} />
                </p>
              </div>

              <form
                className="w-full py-4 px-5  flex flex-col gap-[19px]"
                onSubmit={(e) => {
                  e.preventDefault();
                  setOpen((prev) => ({
                    ...prev,
                    change: true,
                    selectQuestion: false,
                  }));
                }}
              >
                <div className="flex flex-col gap-[10px]">
                  <label
                    htmlFor="SurveyName"
                    className="text-[#333] text-[14px] font-[500]"
                  >
                    Search Question
                  </label>
                  <input
                    type="text"
                    placeholder="Question Name"
                    onChange={(e) =>
                      setInputData((prev) => ({
                        ...prev,
                        searchQuestion: e.target.value,
                      }))
                    }
                    name="searchQuestion"
                    className="border border-[#E2E2E5] rounded-[4px] pl-3 h-[40px] focus:outline-none text-[#666] text-[14px]"
                  />
                </div>
                <div className="flex flex-col gap-[10px] max-h-[200px] overflow-y-auto containerScroll border border-[#E2E2E5] py-2 px-4">
                  {filteredAddQuota?.length ? (
                    filteredAddQuota?.map((d) => (
                      <div className="w-full pr-2 flex items-center gap-[16px] py-2  ">
                        <input
                          type="checkbox"
                          id={d?.question?.questionName}
                          name={d?.question?.questionName}
                          required={inputData?.selectedQuestion ? false : true}
                          checked={inputData?.selectedQuestion === d?._id}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setInputData((prev) => ({
                                ...prev,
                                selectedQuestion: d?._id,
                              }));
                            } else {
                              setInputData((prev) => ({
                                ...prev,
                                selectedQuestion: "",
                              }));
                            }
                          }}
                          className="w-[17px] h-[17px]"
                        />
                        <label
                          htmlFor={d?.question?.questionName}
                          className="text-[14px] font-[400] text-[#000] w-[90%] cursor-pointer"
                        >
                          {d?.question?.questionName || "-"}
                        </label>
                      </div>
                    ))
                  ) : (
                    <h1 className="text-[14px] text-red-400">
                      Try adding new qualification
                    </h1>
                  )}
                </div>

                <button
                  type="submit"
                  className="text-[#fff] inter text-[16px] font-[400] bg-[#0226BE] rounded-[4px] py-2 w-[100px] self-center"
                >
                  Change
                </button>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* change quota */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open?.change}
        onClose={(e) => {
          handleClose("change");
          setLoading(false);
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open?.change}>
          <Box sx={{ ...style, width: 600 }}>
            <div className="w-full flex items-center flex-col">
              <div className="w-full pt-6 pb-4  text-center relative">
                <h1 className="text-[#333333] text-[20px] font-[600]">
                  Change Qualification Quota
                </h1>
                <p
                  className=" bg-[#F1F1F1] py-1 px-1 rounded-[50%] w-fit absolute top-3 cursor-pointer right-4"
                  onClick={(e) => {
                    handleClose("change");
                    setLoading(false);
                  }}
                >
                  <RxCross2 size={16} />
                </p>
              </div>

              <form
                className="w-full  flex flex-col gap-[18px] py-4 items-center"
                onSubmit={handleSubmit}
              >
                <div className="w-[95%] pb-4   flex flex-col gap-[19px]  border border-[#E0E0E0] rounded-[4px]  ">
                  <div className="w-full pt-4 px-5 pb-2 flex flex-wrap justify-between gap-[10px] ">
                    <h1 className="text-[#333] font-[500] text-[14px] capitalize">
                      {qualificationTable?.find(
                        (d) => d?._id === inputData?.selectedQuestion
                      )?.question?.questionText || "-"}
                    </h1>
                    <p className="text-[#333] font-[600] text-[15px]">
                      Total Quotas- {data?.expectedMatrix?.expectedCompletes}
                    </p>
                  </div>
                  <div className="flex flex-col gap-[18px] px-5 max-h-[200px]  overflow-y-auto containerScroll ">
                    {qualificationTable?.find(
                      (d) => d?._id === inputData?.selectedQuestion
                    )?.question?.questionType === "Numeric - Open-end" ? (
                      <>
                        {qualificationTable
                          ?.find((d) => d?._id === inputData?.selectedQuestion)
                          ?.validOptions?.map((d, i) => (
                            <div className="w-[94%]  flex gap-[14px] items-center justify-between pb-5">
                              <div className="flex items-center gap-[14px]">
                                <input
                                  type="Number"
                                  className="border border-[#E0E0E0] w-[100px] h-[40px] rounded-[4px] bg-[#F0F0F0] pl-3 text-[14px] py-[4px] px-[8px]"
                                  value={d?.from}
                                />
                                <p>To</p>
                                <input
                                  type="Number"
                                  className="border border-[#E0E0E0] w-[100px] h-[40px] rounded-[4px] bg-[#F0F0F0] pl-3 text-[14px] py-[4px] px-[8px]"
                                  value={d?.to}
                                />
                              </div>
                              -
                              <input
                                type="Number"
                                value={
                                  inputData?.quota?.[`${d?.from}-${d?.to}`] ||
                                  ""
                                }
                                className="border border-[#E0E0E0] w-[100px] h-[40px] rounded-[4px] bg-[#F0F0F0] pl-3 text-[14px] py-[4px] px-[8px]"
                                onKeyDown={(e) => {
                                  if (
                                    e.key === "e" ||
                                    e.key === "+" ||
                                    e.key === "-"
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onChange={(e) => {
                                  if (
                                    /^\d*$/.test(e.target.value) ||
                                    e.target.value == ""
                                  ) {
                                    if (
                                      Number(e.target.value) <=
                                      Number(
                                        data?.expectedMatrix?.expectedCompletes
                                      )
                                    ) {
                                      setInputData((prev) => ({
                                        ...prev,
                                        quota: {
                                          ...prev.quota,
                                          [`${d?.from}-${d?.to}`]: Number(
                                            e.target.value
                                          ),
                                        },
                                      }));
                                    } else {
                                      setSnackbar({
                                        open: true,
                                        msg: "Allocation cant be more than expected completes",
                                        severity: "error",
                                      });
                                    }
                                  }
                                }}
                              />
                            </div>
                          ))}
                      </>
                    ) : (
                      qualificationTable
                        ?.find((d) => d?._id === inputData?.selectedQuestion)
                        ?.validOptions?.map((d, i) => (
                          <div
                            className="w-full  flex items-center justify-between "
                            key={i}
                          >
                            <h1 className="capitalize  font-[400] text-[14px]">
                              {d}
                            </h1>
                            <input
                              type="Number"
                              value={inputData?.quota?.[d] || ""}
                              className="border border-[#E0E0E0] w-[100px] h-[30px] rounded-[4px] bg-[#F0F0F0] pl-3 text-[14px] py-[4px] px-[8px]"
                              onKeyDown={(e) => {
                                if (
                                  e.key === "e" ||
                                  e.key === "+" ||
                                  e.key === "-"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                if (
                                  /^\d*$/.test(e.target.value) ||
                                  e.target.value == ""
                                ) {
                                  if (
                                    Number(e.target.value) <=
                                    Number(
                                      data?.expectedMatrix?.expectedCompletes
                                    )
                                  ) {
                                    setInputData((prev) => ({
                                      ...prev,
                                      quota: {
                                        ...prev.quota,
                                        [d]: Number(e.target.value),
                                      },
                                    }));
                                  } else {
                                    setSnackbar({
                                      open: true,
                                      msg: "Allocation cant be more than expected completes",
                                      severity: "error",
                                    });
                                  }
                                }
                              }}
                            />
                          </div>
                        ))
                    )}
                  </div>
                </div>
                {loading ? (
                  <div className="flex justify-center w-full">
                    <CircularProgress className="text-center" size={23} />
                  </div>
                ) : (
                  <button
                    type="submit"
                    className="text-[#fff] inter text-[16px] font-[400] bg-[#0226BE] rounded-[4px] py-2 px-6 "
                  >
                    Save
                  </button>
                )}
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* edit quota */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={editQuotaModal}
        onClose={(e) => {
          setEditQuotaModal(false);
          setLoading(false);
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={editQuotaModal}>
          <Box sx={{ ...style, width: 600 }}>
            <div className="w-full flex items-center flex-col  ">
              <div className="w-full pt-6 pb-4  text-center relative">
                <h1 className="text-[#333333] text-[20px] font-[600]">
                  Change Qualification Quota
                </h1>
                <p
                  className=" bg-[#F1F1F1] py-1 px-1 rounded-[50%] w-fit absolute top-3 cursor-pointer right-4"
                  onClick={(e) => {
                    setEditQuotaModal(false);
                  }}
                >
                  <RxCross2 size={16} />
                </p>
              </div>
              <form
                className="w-full  flex flex-col gap-[18px] py-4 items-center"
                onSubmit={handleSubmit}
              >
                <div className="w-[95%] pb-4   flex flex-col gap-[19px]  border border-[#E0E0E0] rounded-[4px]  ">
                  <div className="w-full pt-4 px-5 pb-2 flex flex-wrap justify-between gap-[10px] ">
                    <h1 className="text-[#333] font-[500] text-[14px] capitalize">
                      {qualificationTable?.find(
                        (d) => d?._id === selectedRows?.[0]?._id
                      )?.question?.questionText || "-"}
                    </h1>
                    <p className="text-[#333] font-[600] text-[15px]">
                      Total Quotas- {data?.expectedMatrix?.expectedCompletes}
                    </p>
                  </div>
                  <div className="flex flex-col gap-[18px] px-5 max-h-[200px]  overflow-y-auto containerScroll ">
                    {qualificationTable?.find(
                      (d) => d?._id === selectedRows?.[0]?._id
                    )?.question?.questionType === "Numeric - Open-end" ? (
                      <>
                        {qualificationTable
                          ?.find((d) => d?._id === selectedRows?.[0]?._id)
                          ?.validOptions?.map((d, i) => (
                            <div className="w-[94%]  flex gap-[14px] items-center justify-between pb-5">
                              <div className="flex items-center gap-[14px]">
                                <input
                                  type="Number"
                                  className="border border-[#E0E0E0] w-[100px] h-[40px] rounded-[4px] bg-[#F0F0F0] pl-3 text-[14px] py-[4px] px-[8px]"
                                  value={d?.from}
                                />
                                <p>To</p>
                                <input
                                  type="Number"
                                  className="border border-[#E0E0E0] w-[100px] h-[40px] rounded-[4px] bg-[#F0F0F0] pl-3 text-[14px] py-[4px] px-[8px]"
                                  value={d?.to}
                                />
                              </div>
                              -
                              <input
                                type="Number"
                                value={
                                  selectedRows?.[0]?.[`${d?.from}-${d?.to}`] ||
                                  ""
                                }
                                className="border border-[#E0E0E0] w-[100px] h-[40px] rounded-[4px] bg-[#F0F0F0] pl-3 text-[14px] py-[4px] px-[8px]"
                                onKeyDown={(e) => {
                                  if (
                                    e.key === "e" ||
                                    e.key === "+" ||
                                    e.key === "-"
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onChange={(e) => {
                                  if (
                                    /^\d*$/.test(e.target.value) ||
                                    e.target.value == ""
                                  ) {
                                    if (
                                      Number(e.target.value) <=
                                      Number(
                                        data?.expectedMatrix?.expectedCompletes
                                      )
                                    ) {
                                      let Obj = selectedRows?.[0];
                                      Obj[`${d?.from}-${d?.to}`] = Number(
                                        e.target.value
                                      );
                                      setSelectedRows([Obj]);
                                    } else {
                                      setSnackbar({
                                        open: true,
                                        msg: "Allocation cant be more than expected completes",
                                        severity: "error",
                                      });
                                    }
                                  }
                                }}
                              />
                            </div>
                          ))}
                      </>
                    ) : (
                      qualificationTable
                        ?.find((d) => d?._id === selectedRows?.[0]?._id)
                        ?.validOptions?.filter((d, i) =>
                          selectedRows?.[0]?.hasOwnProperty(d)
                        )
                        ?.map((d, i) => (
                          <div
                            className="w-full  flex items-center justify-between "
                            key={i}
                          >
                            <h1 className="capitalize  font-[400] text-[14px]">
                              {d}
                            </h1>
                            <input
                              type="Number"
                              // value={inputData?.quota?.[d] || ""}
                              value={selectedRows?.[0]?.[d] || ""}
                              className="border border-[#E0E0E0] w-[100px] h-[30px] rounded-[4px] bg-[#F0F0F0] pl-3 text-[14px] py-[4px] px-[8px]"
                              onKeyDown={(e) => {
                                if (
                                  e.key === "e" ||
                                  e.key === "+" ||
                                  e.key === "-"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                if (
                                  /^\d*$/.test(e.target.value) ||
                                  e.target.value == ""
                                ) {
                                  if (
                                    Number(e.target.value) <=
                                    Number(
                                      data?.expectedMatrix?.expectedCompletes
                                    )
                                  ) {
                                    let Obj = selectedRows?.[0];
                                    Obj[d] = Number(e.target.value);
                                    setSelectedRows([Obj]);
                                  } else {
                                    setSnackbar({
                                      open: true,
                                      msg: "Allocation cant be more than expected completes",
                                      severity: "error",
                                    });
                                  }
                                }
                              }}
                            />
                          </div>
                        ))
                    )}
                  </div>
                </div>
                {loading ? (
                  <div className="flex justify-center w-full">
                    <CircularProgress className="text-center" size={23} />
                  </div>
                ) : (
                  <button
                    type="submit"
                    className="text-[#fff] inter text-[16px] font-[400] bg-[#0226BE] rounded-[4px] py-2 px-6 "
                  >
                    Update
                  </button>
                )}
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default Quotas;
